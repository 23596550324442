import React from "react";
import toast, { Toaster } from "react-hot-toast";

const commonToasts = {
  errorToast,
  successToast,
  warnToast,
  infoToast,
};

export default commonToasts;

function errorToast(data) {
  toast.error(data, {
    duration: 8000,
    position: "top-right",
    className: "toast-div-address whitespace-break",
  });
}

function successToast(data) {
  toast.success(data, {
    duration: 5000,
    position: "top-right",
    className: "toast-div-address-success whitespace-break",
  });
}

function warnToast(data) {
  toast(data, {
    duration: 7000,
    position: "top-right",
    className: "toast-div-warn whitespace-break bg-yellow-500 text-black",
    icon: "⚠️", // Add an icon for "warn"
  });
}

function infoToast(data) {
  toast(data, {
    duration: 7000,
    position: "top-right",
    className: "toast-div-info whitespace-break bg-blue-500 text-black",
    icon: "ℹ️", // Add an icon for "info"
  });
}
