import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import Papa from "papaparse";
import axios from "axios";
import SidebarContent from "./Sidebar";
import { Table } from "react-bootstrap";
import commonToasts from "./CommonToast";

const Transactions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [selectedFile, setselectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [SortFieldDB, setSortFieldDB] = useState(null);
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState(null);
  const [data, setdata] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [columns, setcolumns] = useState(null);
  const [nonCreatedStores, setNonCreatedStores] = useState([]);
  const [aggregatedStores, setAggregatedStores] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [createStoreModal, setCreateStoreModal] = useState(false);
  const [mergedStoresArr, setMergedStoresArr] = useState([]);
  const [isNonCreatedChecked, setIsNonCreatedChecked] = useState("");
  const [isCreateStoreActive, setIsCreateStoreActive] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(""); // State to track the selected file type

  const Transactionkeys = [
    "Zone",
    "Store Name",
    "City",
    "POS",
    "Hardware Model",
    "Hardware ID",
    "Acquirer",
    "TID",
    "MID",
    "Batch No",
    "Payment Mode",
    "Customer Payment Mode ID",
    "Name",
    "Card Issuer",
    "Card Type",
    "Card Network",
    "Card Colour",
    "Transaction ID",
    "Invoice",
    "Approval Code",
    "Type",
    "Amount",
    "TIP Amount",
    "Currency",
    "Date",
    "Batch Status",
    "Txn Status",
    "Settlement Date",
    "Bill Invoice",
    "RRN",
    "EMI Txn",
    "EMI Month",
    "Contactless",
    "Contactless Mode",
    "Cloud Ref ID",
    "Card Pan Check for Sale Complete",
    "Route Preauth to Other Acquirer",
    "Billing Transaction Id",
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const handleFileTypeChange = (e) => {
    setSelectedFileType(e.target.value);
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      setIsLoading(true);

      await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getTransactionData`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "json",
        // withCredentials: true,

        data: {
          sortField: SortFieldDB,
          searchValue: searchValue,
          searchFilter: selectedSearchFilter,
          page: page,
          sortOrder: !sortOrder ? "asc" : sortOrder,
        },
      }).then((response) => {
        if (response) {
          var datax = response.data.data;

          // datax = datax.stores;

          setdata(datax);
          if (datax.length > 0) {
            setTotalCount(response.data.totalCount);
            setcolumns(Object.keys(datax[0]));
          }
        }
      });
    } catch (err) {
      commonToasts.errorToast("error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const arrayToCsv = (headers, data) => {
    const csvRows = [];

    // Get headers
    const headerValues = headers.map((header) => header);
    csvRows.push(headerValues.join(","));

    // Get rows
    for (const row of data) {
      const rowValues = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"'); // Escape quotes
        return `"${escaped}"`; // Enclose in quotes
      });
      csvRows.push(rowValues.join(","));
    }
    return csvRows.join("\n"); // Join rows with new line
  };

  const download = (data, fileName) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName + ".csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleUpload = async () => {
    setIsUploading(true);
    const file = selectedFile;
    var email = localStorage.getItem("email");
    var keys = Transactionkeys;
    if (!file) {
      //console.error("No file selected.");
      setIsUploading(false);
      return;
    }
    if (file.size > 100 * 1024 * 1024) {
      Swal.fire({
        icon: "error",
        title: "File too large",
        text: "The selected file exceeds the 100MB size limit.",
        confirmButtonText: "OK",
      });
      setIsUploading(false);
      return;
    }

    let allErrors = []; // Accumulate all errors here

    // Set initial progress
    let progress = 0;

    // Update progress for file reading
    const fileReadProgressRatio = 0.4; // 40% for file reading
    progress += fileReadProgressRatio * 100;
    setProgress(progress);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        var jsonData = results.data;
        jsonData = jsonData.filter(
          (row) => row["Txn Status"]?.toLowerCase() !== "session expired"
        );

        setJsonData(jsonData);
        try {
          // const sheetKeys = Object.keys(jsonData[0]);
          // setFileHeaders(sheetKeys);
          // const missingKeys = keys.filter((key) => !sheetKeys.includes(key));
          // if (missingKeys.length > 0) {
          //   allErrors.push(
          //     `The following keys are missing in the uploaded file: ${missingKeys.join(
          //       ", "
          //     )}`
          //   );
          // }
          //

          // File reading completed, now upload encrypted data
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const uploadProgress = (loaded / total) * 0.6 * 100; // 60% for uploading
              setProgress(progress + uploadProgress);
            },
          };
          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileType", selectedFileType);
          formData.append("fileName", file.name);
          formData.append("fileSize", file.size);
          formData.append("UploadedBy", email);

          const uniqueStoreNames = [
            ...new Set(
              results?.data?.map((store) => {
                // Clean both "Store Name" and "Merchant_Name" fields
                const storeName = (store?.["Store Name"] || "").replace(
                  /^['"]|['"]$/g,
                  ""
                );
                const merchantName = (store?.["Merchant_Name"] || "").replace(
                  /^['"]|['"]$/g,
                  ""
                );
                // Return the first available value, either "Store Name" or "Merchant_Name"
                return storeName || merchantName;
              })
            ),
          ];

          const token = localStorage.getItem("token");
          const check_if_exists = await axios.post(
            process.env.REACT_APP_API_LINK + "/rfapi/getNonCreatedStores",
            { storeNames: uniqueStoreNames },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (check_if_exists?.data?.data.length > 0) {
            const filtered_data = check_if_exists?.data?.data.filter(
              (item) => item !== ""
            );

            setNonCreatedStores(filtered_data);
            let unique_aggregated_stores = uniqueStoreNames.filter(
              (item) => !filtered_data.includes(item)
            );

            setAggregatedStores(unique_aggregated_stores);
          } else {
            // Simulate uploading delay (remove this in production)
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await axios.post(
              process.env.REACT_APP_API_LINK + "/rfapi/uploadTransactionsData2",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
              options
            );

            if (
              response.data.message ===
              "Data received, decrypted, and upserted successfully."
            ) {
              Swal.fire({
                title: "Success",
                text: "File Uploaded Successfully",
                icon: "success",
              });
              // fetchData();
              toggleModal(true);
            }
          }

          // Update progress to 100% when response is received
          setProgress(100);
        } catch (error) {
          //console.error("Error uploading file:", error);
          Swal.fire({
            title: "Error",
            text: "Error Uploading the File",
            icon: "error",
          });
        } finally {
          setIsUploading(false);
          if (allErrors.length > 0) {
            const errorMessage = allErrors
              .map((err) => `<p>${err}</p>`)
              .join("");
            Swal.fire({
              title: "Error",
              html: `<p>
                          Error uploading file:</p>${errorMessage}`,
              icon: "error",
            });
          }
        }
      },
      error: (error) => {
        //console.error("Error parsing CSV:", error);
        setIsUploading(false);
        Swal.fire({
          title: "Error",
          text: "Error Parsing CSV File",
          icon: "error",
        });
      },
    });
  };

  const handleCsv = () => {
    const data = jsonData.filter((item) =>
      selectedEntries.includes(item["Store Name"])
    );

    const csvData = arrayToCsv(fileHeaders, data);
    download(csvData, "transactions.csv");
  };

  const handleFileChange = (e) => {
    try {
      setselectedFile(e.target.files[0]);
    } catch (error) {}
  };

  const handleNonCreatedStores = (item) => {
    setIsNonCreatedChecked(item);
  };

  const handleMergeStoreCheckbox = () => {
    setIsNonCreatedChecked("");
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
          backgroundColor: "#f5f5f5",
        }}
      >
        <SidebarContent />
        <div
          style={{
            padding: "0%",
            margin: "0%",
            flex: 1,
          }}
        >
          <NavV />
          <div
            style={{
              padding: "1.52%",
              backgroundColor: "#f5f5f5",
            }}
          >
            <div
              style={{
                borderRadius: "20px",
                padding: "1.52%",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  margin: 0,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="d-flex flex-wrap"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "stretch",
                  }}
                >
                  <div
                    className="custButton float-right"
                    onClick={() => {
                      // setUploadTransactions(false);
                      toggleModal();
                    }}
                  >
                    <p className="custButton-text">Upload Transactions</p>
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="custButton-icon"
                    />
                  </div>
                </div>
              </div>
              <p>UNIQUE STORES</p>
              {aggregatedStores.length > 0 &&
                aggregatedStores.map((item) => {
                  return (
                    <div>
                      <div>{item}</div>
                      <div>
                        <label for="merge">Merge Store</label>
                        <input
                          type="checkbox"
                          name="merge"
                          onChange={(e) =>
                            setSelectedEntries((prev) => [...prev, item])
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              <p>NON-CREATED STORES</p>
              {nonCreatedStores.length > 0 &&
                nonCreatedStores.map((item) => {
                  return (
                    <div>
                      <div>{item}</div>
                      <div>
                        <label for="merge">Merge Store</label>
                        <input
                          type="checkbox"
                          name="merge"
                          onChange={(e) => {
                            handleNonCreatedStores(item);
                            setSelectedEntries((prev) => [...prev, item]);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              <div>
                <button onClick={() => handleCsv()}>Generate CSV</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modalx">
          <div className="modalx-content">
            {/* selectedFile input */}
            <div style={{ alignSelf: "flex-end" }} onClick={toggleModal}>
              {" "}
              close
            </div>

            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
              draggable="false"
              className={"lovin-it2"}
              alt="..."
            />
            {!IsUploading && (
              <>
                {/* File Type Selection */}
                <label htmlFor="fileTypeSelect">Select File Type:</label>
                <select
                  id="fileTypeSelect"
                  value={selectedFileType}
                  onChange={handleFileTypeChange}
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  <option value="">Select a file type</option>
                  <option value="paytm">Paytm TRM file</option>
                  <option value="pine">Pine TRM file</option>
                </select>{" "}
                <label htmlFor="fileInput">Choose a file:</label>
                <input
                  type="file"
                  id="fileInput"
                  accept=".csv"
                  onChange={handleFileChange}
                  style={{ display: "none" }} // Hide the input element
                />
                <button
                  className="custButton2"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  Select File
                </button>
              </>
            )}
            {/* Display selected selectedFile */}
            {selectedFile && !IsUploading && (
              <>
                <p>File: {selectedFile.name}</p>
                {/* Button to trigger selectedFile upload */}
                <button className="custButton2" onClick={handleUpload}>
                  Upload File
                </button>
              </>
            )}
            {/* Upload progress */}
            {IsUploading && (
              <div>
                {/* <h2>Uploading selectedFile...</h2> */}
                <progress value={progress} max="100"></progress>
              </div>
            )}
            {/* TABLE RENDERING */}
          </div>
        </div>
      )}
      {isNonCreatedChecked !== "" && (
        <div className="modalx">
          <div className="modalx-content">
            {/* selectedFile input */}
            <div style={{ alignSelf: "flex-end" }} onClick={toggleModal}>
              {" "}
              close
            </div>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
              draggable="false"
              className={"lovin-it2"}
              alt="..."
            />
            This Store is not created do you want to create one ?
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Transactions;

const NavV = () => {
  return (
    <p
      className="align-self-end"
      style={{
        padding: "2%",
        display: "flex",
        backgroundColor: "white",
        margin: 0,
        marginTop: "0rem",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: "0.1px solid gray",
        // , marginBottom: 20
      }}
    >
      {"Home > "}
      <p
        className="align-self-end"
        style={{
          margin: 0,
          marginTop: "0rem",
          marginLeft: "0.51rem",
          fontWeight: "700",
        }}
      >
        {"Transactions"}
      </p>
    </p>
  );
};

// <>
// {
//   data?.length > 0 && (
//     <div>
//        <Table
//           className="mt-3"
//           hover
//           style={{
//             textAlign: "center",
//             alignItems: "center",
//             width: "100%",
//           }}
//           responsive
//         >
//           <thead>
//             <tr
//               style={{
//                 color: "#809FB8",
//                 borderBottomWidth: 1,
//                 borderBottomColor: "#b4a7d6",
//                 fontWeight: "600",
//                 textTransform: "uppercase",
//                 height: 50,
//                 verticalAlign: "middle",
//               }}
//             >
//               {columns.map((column) => (
//                 <td
//                   key={column}
//                   onClick={() => {
//                     // handleSort(column);
//                   }}
//                   style={{
//                     cursor: "pointer",
//                   }}
//                 >
//                   {column}
//                 </td>
//               ))}
//               <td>Action</td>
//             </tr>
//           </thead>
//           <tbody>
//             {data.map((item, index) => (
//               <tr key={index}>
//                 {columns.map((field, fieldindex) => (
//                   <td
//                     key={`${fieldindex}`}
//                     style={{
//                       color: "#06152B",
//                       fontWeight: "400",
//                       fontSize: "0.81rem",
//                     }}
//                   >
//                     <p>{item[field]}</p>
//                   </td>
//                 ))}

//               </tr>
//             ))}
//           </tbody>
//           {/* <h1>asdkjnaskds</h1> */}
//         </Table>
//     </div>
//   )
// }
// </>
