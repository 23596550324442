import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./Home";
import Login from "./Login";
import authService from "./utils/authService";
import {
  getToken,
  removeToken,
  getRefreshToken,
  removeRefreshToken,
  setRefreshToken,
  isAuthenticated,
} from "./utils/auth";
import Register from "./Register";
import "./custom_c.scss";
import NotLoggedInPage from "./NotLoggedInPage";
import Distributor from "./components/Distributor";
import Stores from "./components/Stores";
import Reports from "./components/Reports";
import AllRecords from "./components/AllRecords";
import ConflictRecords from "./components/ConflictRecords";
import FileStatus from "./components/FileStatus";
import AdminControl from "./components/AdminControl";
import ApprovalTray from "./components/ApprovalTray";
import Managers from "./components/Managers";
import Transactions from "./components/Transactions";
import useSessionHook from "./customHooks/useSessionHook";
import SessionExpiredPopup from "./components/refreshPopup";
import { Toaster } from "react-hot-toast";

const App = () => {
  const [tokenExpired, setTokenExpired] = useState(false);
  const [isTokenValid] = useSessionHook();
  const BASE_URL = process.env.API_LINK;

  useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      try {
        const token = getToken();
        // If no token is present, consider it expired
        if (!token) {
          setTokenExpired(true);
          return;
        }

        // Decode the token to get expiration time
        const decodedToken = authService.decodeToken(token);
        const currentTime = Math.floor(Date.now() / 1000);

        // If the token is expired, set the state to true
        if (decodedToken.exp < currentTime) {
          setTokenExpired(true);
          // removeToken();
          // removeRefreshToken();
          handleTokenRefresh();
        } else {
          // Calculate the remaining time before token expiration
          const timeLeftInSeconds = decodedToken.exp - currentTime;
        }
      } catch (error) {
        // If there's an error decoding the token, consider it expired
        setTokenExpired(true);
      }
    };

    // Call the function to check token expiration
    checkTokenExpiration();
  }, []); //

  const handleLogout = () => {
    removeToken();
    removeRefreshToken();
  };

  const handleTokenRefresh = async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
      handleLogout();
      return;
    }

    try {
      const newToken = await authService.refreshToken(refreshToken);
      setRefreshToken(newToken);
    } catch (error) {
      //console.error("Token refresh failed:", error);
      handleLogout();
    }
  };
  return (
    <React.Fragment>
      <Toaster />
      {Boolean(!isTokenValid) && localStorage.getItem("token") && (
        <SessionExpiredPopup />
      )}

      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/" element={<Login />} />
        {!isAuthenticated() ? (
          <Route path="/*" element={<NotLoggedInPage />} />
        ) : (
          <>
            {/* <Route path="/a/*" element={<Distributor />} /> */}
            {localStorage.getItem("role") === "maker" ? (
              <>
                <Route path="/stores" element={<Stores />} />
                <Route path="/FileStatus" element={<FileStatus />} />
                <Route path="/Transactions" element={<Transactions />} />
                <Route path="/managers" element={<Managers />} />
              </>
            ) : (
              <></>
            )}
            {localStorage.getItem("role") === "checker" ? (
              <>
                <Route path="/ApprovalTray" element={<ApprovalTray />} />
              </>
            ) : (
              <></>
            )}
            <Route path="/dashboard" element={<Stores />} />
            <Route path="/AllRecords" element={<AllRecords />} />
            <Route path="/ConflictRecords" element={<ConflictRecords />} />

            <Route path="/reports" element={<Reports />} />
            <Route path="/AdminControl" element={<AdminControl />} />
          </>
        )}
      </Routes>
    </React.Fragment>
  );
};
export default App;
