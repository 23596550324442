import React, { useEffect, useState } from "react";

const useSessionHook = () => {
  const token = localStorage.getItem("token");
  const [valid, setValid] = useState(true);

  // * To verify the token on loading the screen
  useEffect(() => {
    checkUpdateFunc();
    return () => null;
  }, []);

  // * Listen Event to check the token
  window.addEventListener("mousemove", () => {
    checkUpdateFunc();
  });

  //* common function to verify token
  const checkUpdateFunc = () => {
    if (token) {
      const v = isTokenExpired(token);
      if (v) {
        setValid(false);
      }
    }
  };

  // * Check expiry time from access Token
  function isTokenExpired(token) {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
  return [valid];
};

export default useSessionHook;
