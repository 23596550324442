import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { components } from "react-select";
import { Button, Pagination, Table } from "react-bootstrap";
import search_icon from "../assets/search_icon.svg";
import download_icon from "../assets/download_icon.svg";
import refresh_icon from "../assets/refresh_icon.svg";
import Calendar from "react-calendar";
import { default as ReactSelect } from "react-select";
import "./Main.scss";
import McDLogo from "./assets/McDLogo.png";
import axios from "axios";
import * as XLSX from "xlsx";
import { GDSRecords } from "../ENDE";
import Swal from "sweetalert2";

import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faPlus,
  faDownload,
  faSort,
  faEdit,
  faRefresh,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import SidebarContent from "./Sidebar";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import commonToasts from "./CommonToast";
// const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

export default function FileStatus() {
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [isDateConstVisible, setIsDateConstVisible] = useState(false);
  const [isSortFieldDBVisible, setisSortFieldDBVisible] = useState(false);

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const [selectedFile, setselectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [SortFieldDB, setSortFieldDB] = useState(null);
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [columns, setcolumns] = useState(null);
  const [nonCreatedStores, setNonCreatedStores] = useState([]);
  const [aggregatedStores, setAggregatedStores] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [createStoreModal, setCreateStoreModal] = useState(false);
  const [isCreateStoreActive, setIsCreateStoreActive] = useState(false);
  const [DateConst, setDateConst] = useState(null);
  const [storeFormData, setStoreFormData] = useState({
    store_id: null,
    store_code: null,
    store_name: null,
    location: null,
    pin_code: null,
  });
  const [storeCreateWarningModal, setStoreCreateWarningModal] = useState(false);

  const keys = [
    "id",
    "fileName",
    "fileSize",
    "uploadStatus",
    "processingStatus",
    "uploadTimestamp",
    "processingTimestamp",
    "affectedRows",
    "tableName",
    "UploadedBy",
    "skippedRows",
  ];
  const Transactionkeys = [
    "Zone",
    "Store Name",
    // "Store Code",
    "City",
    "POS",
    "Hardware Model",
    "Hardware ID",
    "Acquirer",
    "TID",
    "MID",
    "Batch No",
    "Payment Mode",
    "Customer Payment Mode ID",
    "Name",
    "Card Issuer",
    "Card Type",
    "Card Network",
    "Card Colour",
    "Transaction ID",
    "Invoice",
    "Approval Code",
    "Type",
    "Amount",
    "TIP Amount",
    "Currency",
    "Date",
    "Batch Status",
    "Txn Status",
    "Settlement Date",
    "Bill Invoice",
    "RRN",
    "EMI Txn",
    "EMI Month",
    "Contactless",
    "Contactless Mode",
    "Cloud Ref ID",
    "Card Pan Check for Sale Complete",
    "Route Preauth to Other Acquirer",
    "Billing Transaction Id",
  ];
  const handleFileChange = (e) => {
    try {
      console.log(e.target.files[0]);
      setselectedFile(e.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const encryptFile = async (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const fileData = new Uint8Array(reader.result);
        const encryptedData = CryptoJS.AES.encrypt(
          fileData.toString(),
          "your-secret-key"
        );
        const blob = new Blob([encryptedData.toString()], {
          type: "application/octet-stream",
        });
        resolve(blob);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  function TableStatus({ text }) {
    const textx = text?.toLowerCase();
    // console.log(textx);
    // console.log(textx === 'failed');
    const color =
      textx === "failed"
        ? "#EB5757"
        : textx === "cancelled"
        ? "#F2994A"
        : "#12A474";
    const bgcolor =
      textx === "failed"
        ? "#FFDBDB"
        : textx === "cancelled"
        ? "#FFF3DB"
        : "#BBF3E0";
    return (
      <span
        style={{
          backgroundColor: bgcolor,
          color: color,
          fontSize: 12,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 8,
          textTransform: "capitalize",
        }}
      >
        {text}
      </span>
    );
  }
  const toggleModal = (close) => {
    setSelectedFileType("");
    setModalOpen(!modalOpen);
    setProgress(0); // Reset progress when modal opens
    if (close === true) {
      setselectedFile(null);
      setSelectedFileType("");
      setModalOpen(false);
    }
  };
  const handleNonCreatedStores = (item) => {
    setIsNonCreatedChecked(item);
    setStoreCreateWarningModal(true);
  };

  const handleMergeStoreCheckbox = () => {
    setIsNonCreatedChecked("");
  };
  const [mergedStoresArr, setMergedStoresArr] = useState([]);
  const [isNonCreatedChecked, setIsNonCreatedChecked] = useState("");
  const [sortOrder, setSortOrder] = useState(null);
  const [data, setdata] = useState(null);
  const [editMode, seteditMode] = useState(false);
  const [editedValues, setEditedValues] = useState(
    Array(data?.length).fill({})
  );
  const [selectedFileType, setSelectedFileType] = useState(""); // State to track the selected file type

  const [EditRow, setEditRow] = useState([]);
  const toggleEditMode = () => {
    seteditMode(!editMode);
  };

  const handleInputChange = (e, field, index, id) => {
    const { value } = e.target;
    console.log(field);
    // Validation: Check if the field is either "pincode" or "store_id" and if the value is a number
    if (
      (field === "PIN_CODE" || field === "STORE_ID") &&
      isNaN(Number(value))
    ) {
      // If the value is not a number, you can handle the error here
      //console.error(`Invalid input for ${field}: ${value}`);
      // Don't update the state if the input is invalid
      return;
    }
    setEditRow((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const ccList = [
    { value: "id", label: "ID" },
    { value: "fileName", label: "File Name" },
    { value: "fileSize", label: "File Size" },
    { value: "uploadStatus", label: "Upload Status" },
    { value: "processingStatus", label: "Processing Status" },
    { value: "uploadTimestamp", label: "Upload Timestamp" },
    { value: "processingTimestamp", label: "Processing Timestamp" },
    { value: "affectedRows", label: "Affected Rows" },
    { value: "tableName", label: "Table Name" },
    { value: "UploadedBy", label: "Uploaded By" },
    { value: "skippedRows", label: "Skipped Rows" },
  ];

  const handlePageChange = (value) => {
    setPage(value);
  };
  const pageCount = Math.ceil(totalCount / pageSize);
  const onDateConstChange = (value) => {
    setDateConst(value);
    setIsDateConstVisible(!isDateConstVisible);
  };
  function getDateLabel(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

    if (inputDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return inputDate.toISOString().substring(0, 10);
    }
  }
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      var email = localStorage.getItem("email");
      setIsLoading(true);
      console.log({
        sortField: SortFieldDB,
        searchValue: searchValue,
        searchFilter: selectedSearchFilter,
        date: DateConst,
      });
      await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getFileData`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "json",
        // withCredentials: true,

        data: {
          sortField: SortFieldDB,
          searchValue: searchValue,
          searchFilter: selectedSearchFilter,
          page: page,
          sortOrder: !sortOrder ? "asc" : sortOrder,
          date: DateConst,
          email: email,
        },
      }).then((response) => {
        if (response) {
          var datax = response.data.data;
          console.log(datax);
          // datax = datax.FileStatus;
          console.log(datax);
          console.log(typeof datax);
          setdata(datax);
          if (datax && datax?.length > 0) {
            setTotalCount(response.data.totalCount);
            setcolumns(Object.keys(datax[0]));
          }
        }
      });
    } catch (err) {
      console.log("error fetching ");
      commonToasts.errorToast("error fetching data");
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  // const downloaddata = async () => {
  //   try {
  //     const response = await axios({
  //       url: process.env.REACT_APP_API_LINK + `/rfapi/getStoreData`,
  //       method: "POST",
  //       responseType: "json",
  //       // withCredentials: true,

  //       data: {
  //         sortField: SortFieldDB,
  //         searchValue: searchValue,
  //         searchFilter: selectedSearchFilter,
  //         page: page,
  //         download: true,
  //       },
  //       responseType: "blob", // Set the response type to blob to handle binary data
  //     });

  //     // Create a blob object from the response data
  //     const blob = new Blob([response.data], { type: "text/csv" });

  //     // Create a download link
  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = window.URL.createObjectURL(blob);
  //     downloadLink.download = "data.csv";

  //     // Append the link to the body and click it to trigger the download
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();

  //     // Cleanup
  //     document.body.removeChild(downloadLink);
  //   } catch (error) {
  //     //console.error("Error downloading data:", error);
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpload = async () => {
    setIsUploading(true);
    const file = selectedFile;
    var email = localStorage.getItem("email");
    var keys = Transactionkeys;
    if (!file) {
      //console.error("No file selected.");
      setIsUploading(false);
      return;
    }
    if (file.size > 100 * 1024 * 1024) {
      Swal.fire({
        icon: "error",
        title: "File too large",
        text: "The selected file exceeds the 100MB size limit.",
        confirmButtonText: "OK",
      });
      setIsUploading(false);
      return;
    }

    let allErrors = []; // Accumulate all errors here

    // Set initial progress
    let progress = 0;

    // Update progress for file reading
    const fileReadProgressRatio = 0.4; // 40% for file reading
    progress += fileReadProgressRatio * 100;
    setProgress(progress);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        var jsonData = results.data;
        jsonData = jsonData.filter(
          (row) => row["Txn Status"]?.toLowerCase() !== "session expired"
        );
        console.log("jsonData00==>>>", jsonData);
        setJsonData(jsonData);
        try {
          const sheetKeys = Object.keys(jsonData[0]);
          setFileHeaders(sheetKeys);
          // const missingKeys = keys.filter((key) => !sheetKeys.includes(key));
          // if (missingKeys.length > 0) {
          //   allErrors.push(
          //     `The following keys are missing in the uploaded file: ${missingKeys.join(
          //       ", "
          //     )}`
          //   );
          // }
          console.log("sheetKeys", sheetKeys);

          // File reading completed, now upload encrypted data
          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const uploadProgress = (loaded / total) * 0.6 * 100; // 60% for uploading
              setProgress(progress + uploadProgress);
            },
          };
          const formData = new FormData();
          formData.append("file", file);
          formData.append("fileType", selectedFileType);
          formData.append("fileName", file.name);
          formData.append("fileSize", file.size);
          formData.append("UploadedBy", email);

          const uniqueStoreNames = [
            ...new Set(
              results?.data?.map((store) => {
                // Clean both "Store Name" and "Merchant_Name" fields
                const storeName = (store?.["Store Name"] || "").replace(
                  /^['"]|['"]$/g,
                  ""
                );
                const merchantName = (store?.["Merchant_Name"] || "").replace(
                  /^['"]|['"]$/g,
                  ""
                );
                // Return the first available value, either "Store Name" or "Merchant_Name"
                return storeName || merchantName;
              })
            ),
          ];
          console.log("uniqueStoreNames", uniqueStoreNames);
          const token = localStorage.getItem("token");
          const check_if_exists = await axios.post(
            process.env.REACT_APP_API_LINK + "/rfapi/getNonCreatedStores",
            { storeNames: uniqueStoreNames },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (check_if_exists?.data?.data.length > 0) {
            const filtered_data = check_if_exists?.data?.data.filter(
              (item) => item !== ""
            );
            console.log("filtered_data", filtered_data);
            setNonCreatedStores(filtered_data);
            let unique_aggregated_stores = uniqueStoreNames.filter(
              (item) => !filtered_data.includes(item)
            );
            console.log(
              "unique_aggregated_stores",
              unique_aggregated_stores,
              selectedFile,
              IsUploading
            );

            setAggregatedStores(unique_aggregated_stores);
          } else {
            // Simulate uploading delay (remove this in production)
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const response = await axios.post(
              process.env.REACT_APP_API_LINK + "/rfapi/uploadTransactionsData2",
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
              options
            );
            console.log(response.data, "response");

            if (
              response.data.message ===
              "Data received, decrypted, and upserted successfully."
            ) {
              Swal.fire({
                title: "Success",
                text: "File Uploaded Successfully",
                icon: "success",
              });
              // fetchData();
              toggleModal(true);
            }
          }
          // Update progress to 100% when response is received
          setProgress(100);
        } catch (error) {
          console.error("Error uploading file:", error);
          Swal.fire({
            title: "Error",
            text: "Error Uploading the File",
            icon: "error",
          });
        } finally {
          setIsUploading(false);
          if (allErrors.length > 0) {
            const errorMessage = allErrors
              .map((err) => `<p>${err}</p>`)
              .join("");
            Swal.fire({
              title: "Error",
              html: `<p>
                          Error uploading file:</p>${errorMessage}`,
              icon: "error",
            });
          }
        }
      },
      error: (error) => {
        //console.error("Error parsing CSV:", error);
        setIsUploading(false);
        Swal.fire({
          title: "Error",
          text: "Error Parsing CSV File",
          icon: "error",
        });
      },
    });
  };
  // Handle file type selection
  const handleFileTypeChange = (e) => {
    console.log(e.target.value);

    setSelectedFileType(e.target.value);
  };
  // useEffect(() => {
  //         fetchData(SortFieldDB, searchValue, selectedSearchFilter,page,sortOrder);
  // }, [page]);
  console.log("selected file is", selectedFileType);

  useEffect(() => {
    if (
      SortFieldDB ||
      (searchValue && selectedSearchFilter) ||
      (!searchValue && !selectedSearchFilter)
    ) {
      fetchData();
    }
  }, [
    SortFieldDB,
    searchValue,
    selectedSearchFilter,
    sortOrder,
    page,
    DateConst,
  ]);

  const handleSort = (field) => {
    console.log(field);
    console.log(SortFieldDB);
    console.log(sortOrder);
    if (field === SortFieldDB) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortFieldDB(field);
      setSortOrder("asc");
    }
  };
  const pageNumbers = [];
  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }

  const getPageItems = () => {
    const items = [];

    // Add Prev button
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => {
          if (page > 1) {
            handlePageChange(page - 1);
          }
        }}
        disabled={page <= 1}
      />
    );

    // Add page numbers
    for (
      let i = Math.max(1, page - 4);
      i <= Math.min(pageCount, page + 5);
      i++
    ) {
      items.push(
        <Pagination.Item
          key={i}
          active={page === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add Next button
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => {
          if (page < pageCount) {
            handlePageChange(page + 1);
          }
        }}
        disabled={page >= pageCount}
      />
    );

    return items;
  };

  const NavV = () => {
    return (
      <p
        className="align-self-end"
        style={{
          padding: "2%",
          display: "flex",
          backgroundColor: "white",
          margin: 0,
          marginTop: "0rem",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "0.1px solid gray",
          // , marginBottom: 20
        }}
      >
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            marginLeft: "0.51rem",
            fontWeight: "700",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/FileStatus");
          }}
        >
          {"FileStatus"}
        </p>
      </p>
    );
  };

  const handleCreateStore = async () => {
    console.log("store form data is", storeFormData);
    try {
      // Extract id from editedValues

      // Encrypt the edited data
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(storeFormData),
        "secret_key"
      ).toString();
      console.log(JSON.stringify(storeFormData));
      const token = localStorage.getItem("token");
      // Send the encrypted data to the backend
      const response = await axios.post(
        process.env.REACT_APP_API_LINK + `/rfapi/createStore`,
        {
          data: encryptedData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      if (response.data.message === "Store Created successfully") {
        Swal.fire({
          title: "Success",
          text: "Store updated successfully",
          icon: "success",
        });
        // toggleEditMode(index);
        // fetchData();
        // toggleModal(true);
      }
    } catch (error) {
      //console.error("Error submitting store data:", error);
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message || "Failed to create store",
        icon: "error",
      });
    }
  };

  const handleCreateStoreInputChange = (key, value) => {
    if (key === "store_code") {
      if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 10) {
        setStoreFormData((prev) => ({
          ...prev,
          [key]: value,
        }));
      }
    } else {
      setStoreFormData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleCsv = async () => {
    const data = jsonData.filter((item) =>
      selectedEntries.includes(item["Store Name"])
    );
    console.log("cs data is", data, fileHeaders, selectedEntries, jsonData);
    const csvData = arrayToCsv(fileHeaders, data);
    const blob = new Blob([csvData], { type: "text/csv" });
    // Create a File from the Blob
    const file = new File([blob], selectedFile?.name, { type: "text/csv" });
    // download(csvData, 'transactions.csv');
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", selectedFileType);
    formData.append("fileName", file.name);
    formData.append("fileSize", file.size);
    formData.append("UploadedBy", localStorage.getItem("email"));

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const uploadProgress = (loaded / total) * 0.6 * 100; // 60% for uploading
        setProgress(progress + uploadProgress);
      },
    };
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const token = localStorage.getItem("token");
    const response = await axios.post(
      process.env.REACT_APP_API_LINK + "/rfapi/uploadTransactionsData2",
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      options
    );
    console.log(response.data);

    if (
      response.data.message ===
      "Data received, decrypted, and upserted successfully."
    ) {
      Swal.fire({
        title: "Success",
        text: "File Uploaded Successfully",
        icon: "success",
      });
      // fetchData();
      toggleModal(true);
    }
  };

  const arrayToCsv = (headers, data) => {
    const csvRows = [];

    console.log(headers, "line135");
    // Get headers
    const headerValues = headers.map((header) => header);
    csvRows.push(headerValues.join(","));

    // Get rows
    for (const row of data) {
      const rowValues = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"'); // Escape quotes
        return `"${escaped}"`; // Enclose in quotes
      });
      csvRows.push(rowValues.join(","));
      console.log("csvRows", rowValues);
    }
    return csvRows.join("\n"); // Join rows with new line
  };

  const download = (data, fileName) => {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName + ".csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleCheckboxChange = (item, e, type) => {
    if (type === "non-created" && e.target.checked === true) {
      handleNonCreatedStores(item);
    }
    if (e.target.checked) {
      setSelectedEntries((prev) => [...prev, item]);
    } else {
      setSelectedEntries((prev) => prev.filter((entry) => entry !== item));
    }
  };

  const handleNotCreateStore = () => {
    setStoreCreateWarningModal(false);
    setSelectedEntries((prev) =>
      prev.filter((entry) => entry !== isNonCreatedChecked)
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#f5f5f5",
      }}
    >
      <SidebarContent />
      <div
        style={{
          padding: "0%",
          margin: "0%",
          flex: 1,
        }}
      >
        <NavV />
        <div
          style={{
            padding: "1.52%",
            backgroundColor: "#f5f5f5",
          }}
        >
          <div
            style={{
              borderRadius: "20px",
              padding: "1.52%",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                margin: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h1
                                style={{
                                    display: "inline-block",
                                    width: "27%",
                                    fontSize: "1rem",
                                    fontWeight: "700",
                                }}
                            >
                                FileStatus
                            </h1> */}
              <div
                className="d-flex flex-wrap"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <div className="customx-input-group" style={{ maxWidth: 800 }}>
                  <img
                    src={search_icon}
                    alt=""
                    width={40}
                    style={{
                      padding: 10,
                    }}
                    id="basic-addon1"
                  />
                  <input
                    type="text"
                    className="customx-form-control"
                    style={{
                      minWidth: 400,
                    }}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder="Search here"
                  />
                  <ReactSelect
                    className="customx-form-control border-light"
                    styles={{
                      control: (baseStyle, state) => ({
                        ...baseStyle,
                        borderColor: "white",
                        "&:hover": {
                          borderColor: "white",
                        },
                        boxShadow: 0,
                      }),
                    }}
                    placeholder={"Search Field"}
                    options={ccList}
                    hideSelectedOptions={false}
                    isClearable={true}
                    onChange={setSelectedSearchFilter}
                    value={selectedSearchFilter}
                  />
                </div>

                {modalOpen && (
                  <div className="modalx">
                    <div className="modalx-content">
                      {/* selectedFile input */}
                      <div
                        style={{ alignSelf: "flex-end" }}
                        onClick={toggleModal}
                      >
                        {" "}
                        close
                      </div>

                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                        draggable="false"
                        className={"lovin-it2"}
                      />
                      {!IsUploading && (
                        <>
                          {/* File Type Selection */}
                          <label htmlFor="fileTypeSelect">
                            Select File Type:
                          </label>
                          <select
                            id="fileTypeSelect"
                            value={selectedFileType}
                            onChange={handleFileTypeChange}
                            style={{ display: "block", marginBottom: "10px" }}
                          >
                            <option value="">Select a file type</option>
                            <option value="paytm">Paytm TRM file</option>
                            <option value="pine">Pine TRM file</option>
                          </select>{" "}
                          <label htmlFor="fileInput">Choose a file:</label>
                          <input
                            type="file"
                            id="fileInput"
                            accept=".csv"
                            onChange={handleFileChange}
                            style={{ display: "none" }} // Hide the input element
                          />
                          <button
                            className="custButton2"
                            onClick={() =>
                              document.getElementById("fileInput").click()
                            }
                          >
                            Select File
                          </button>
                        </>
                      )}
                      {selectedFile?.name && <hr />}
                      {/* Display selected selectedFile */}
                      {selectedFile && !IsUploading && (
                        <>
                          <p>File: {selectedFile.name}</p>
                          <>
                            {(aggregatedStores.length > 0 ||
                              nonCreatedStores.length > 0) && (
                              <h2 style={{ fontSize: "12px" }}>
                                Select the checkboxes to merge the data into
                                transactions
                              </h2>
                            )}
                            {aggregatedStores.length > 0 &&
                              aggregatedStores
                                .filter((item) => item !== "")
                                .map((item) => {
                                  return (
                                    <div className="store-checkbox">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>{item}</div>
                                        <div>
                                          <input
                                            type="checkbox"
                                            name="merge"
                                            checked={selectedEntries.includes(
                                              item
                                            )}
                                            // onChange={(e) => setSelectedEntries((prev) => [...prev, item])}
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                item,
                                                e,
                                                "aggregated"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            {nonCreatedStores.length > 0 &&
                              nonCreatedStores.map((item) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>{item}</div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        name="merge-non-created"
                                        checked={selectedEntries.includes(item)}
                                        // onChange={(e) => {
                                        //   handleNonCreatedStores(item)
                                        //   setSelectedEntries((prev) => [...prev, item])
                                        // }}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item,
                                            e,
                                            "non-created"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </>
                          {/* Button to trigger selectedFile upload */}
                          <button
                            className="custButton2"
                            onClick={
                              selectedEntries.length > 0
                                ? handleCsv
                                : handleUpload
                            }
                          >
                            {selectedEntries.length > 0
                              ? "Upload Data"
                              : "Prepare Data"}
                          </button>
                        </>
                      )}

                      {/* Upload progress */}
                      {IsUploading && (
                        <div>
                          <progress value={progress} max="100"></progress>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {storeCreateWarningModal && (
                  <div className="modalx">
                    <div className="modalx-content">
                      {/* selectedFile input */}
                      <div
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => setStoreCreateWarningModal(false)}
                      >
                        {" "}
                        close
                      </div>

                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                        draggable="false"
                        className={"lovin-it2"}
                        alt="..."
                      />
                      <p>
                        The store has not been created yet. Please create the
                        store first in order to proceed with merging the data.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          textAlign: "center",
                        }}
                      >
                        <button
                          className="custButton"
                          onClick={() => setIsCreateStoreActive(true)}
                        >
                          Yes
                        </button>
                        <button
                          className="custButton"
                          onClick={handleNotCreateStore}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                <button
                  className="custButton"
                  // className="custButton"
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={() => {
                    setisSortFieldDBVisible(!isSortFieldDBVisible);
                  }}
                >
                  <p className="custButton-text">
                    {SortFieldDB ? SortFieldDB : "Select Sort"}
                  </p>
                  <FontAwesomeIcon icon={faSort} className="custButton-icon" />
                  {isSortFieldDBVisible && (
                    <div className="calendar-container">
                      <div className="FilterDropDown-menu">
                        {keys.map((key) => (
                          <span
                            key={key}
                            className="FilterDropDown-item-text"
                            onClick={() => {
                              setSortFieldDB(key);
                            }}
                          >
                            {key}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </button>
                {/* <div className="custButton"
                                   onClick={() => downloaddata()}>
                                    <p className="custButton-text">Download</p>
                                    <FontAwesomeIcon icon={faDownload} className="custButton-icon" />
                                </div> */}
                <div className="custButton" onClick={() => fetchData()}>
                  <p className="custButton-text">Refresh</p>
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className="custButton-icon"
                  />
                </div>
                <div
                  className="custButton"
                  onClick={() => {
                    // setUploadTransactions(true);
                    toggleModal();
                  }}
                >
                  <p className="custButton-text">Upload Transactions</p>
                  <FontAwesomeIcon icon={faPlus} className="custButton-icon" />
                </div>
              </div>
            </div>
            <>
              {data?.length > 0 && data != null && !isLoading ? (
                <div>
                  <Table
                    className="mt-3"
                    hover
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                    responsive
                  >
                    <thead>
                      <tr
                        style={{
                          color: "#809FB8",
                          borderBottomWidth: 1,
                          borderBottomColor: "#b4a7d6",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          height: 50,
                          verticalAlign: "middle",
                        }}
                      >
                        {columns?.map((column) => (
                          <td
                            key={column}
                            onClick={() => {
                              handleSort(column);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {column}
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, index) => (
                        <tr key={index}>
                          {columns.map((field, fieldindex) =>
                            field === "processingStatus" ? (
                              <td key={`${fieldindex}`}>
                                {" "}
                                <TableStatus text={item[field]} />
                              </td>
                            ) : (
                              <td
                                key={`${fieldindex}`}
                                style={{
                                  color: "#06152B",
                                  fontWeight: "400",
                                  fontSize: "0.81rem",
                                }}
                              >
                                <p>{item[field]}</p>
                              </td>
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                    {/* <h1>asdkjnaskds</h1> */}
                  </Table>
                  {/* Math.ceil(totalCount / pageSize) */}
                  <Pagination style={{ float: "right", marginTop: "50px" }}>
                    {" "}
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    {getPageItems()}
                    <Pagination.Last
                      onClick={() => handlePageChange(pageCount)}
                    />
                  </Pagination>
                </div>
              ) : isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    height: "70vh",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                    draggable="false"
                    className="lovin-it"
                  />
                  {/* <Spinner animation="border" /> */}
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignSelf: "center",
                      alignItems: "center",
                      height: "70vh",
                      backgroundColor: "white",
                    }}
                  >
                    <h3>No data found</h3>
                  </div>
                </>
              )}

              {isCreateStoreActive && (
                <div className="modalx">
                  <div className="modalx-content">
                    <label className="editlabel">Create Store</label>
                    <div className="fields-container">
                      <div className="field-wrapper">
                        <h2 className="modalxh2">STORE_ID</h2>
                        <input
                          type="text"
                          className="input-fieldx"
                          value={storeFormData?.store_id}
                          onChange={(e) =>
                            handleCreateStoreInputChange(
                              "store_id",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="field-wrapper">
                        <h2 className="modalxh2">STORE_CODE</h2>
                        <input
                          type="text"
                          className="input-fieldx"
                          value={storeFormData?.store_code}
                          onChange={(e) =>
                            handleCreateStoreInputChange(
                              "store_code",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="field-wrapper">
                        <h2 className="modalxh2">STORE_NAME</h2>
                        <input
                          type="text"
                          className="input-fieldx"
                          value={storeFormData?.store_name}
                          onChange={(e) =>
                            handleCreateStoreInputChange(
                              "store_name",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="field-wrapper">
                        <h2 className="modalxh2">LOCATION</h2>
                        <input
                          type="text"
                          className="input-fieldx"
                          value={storeFormData?.location}
                          onChange={(e) =>
                            handleCreateStoreInputChange(
                              "location",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="field-wrapper">
                        <h2 className="modalxh2">PIN_CODE</h2>
                        <input
                          type="text"
                          className="input-fieldx"
                          value={storeFormData?.pin_code}
                          onChange={(e) =>
                            handleCreateStoreInputChange(
                              "pin_code",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <br />
                    <button className="custButton2" onClick={handleCreateStore}>
                      Submit
                    </button>
                    <button
                      onClick={() => setIsCreateStoreActive(false)}
                      className="custButton2"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
