// services/authService.js
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const BASE_URL = process.env.API_LINK;

const authService = {
  login: async (credentials) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/login`, credentials);

      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  refreshToken: async (refreshToken) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/refresh-token`, {
        refreshToken,
      });
      return response.data.token;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  },

  decodeToken: (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      throw error;
    }
  },
};

export default authService;
