import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import commonToasts from "./CommonToast";

export const RefundOTPModalComponent = ({
  item,
  OTPModalOpen,
  setOTPModalOpen,
}) => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [resendOTP, setResendOTP] = useState(false);
  const [timer, setTimer] = useState(60);
  const [confirmationStep, setConfirmationStep] = useState(true); // New state for confirmation step
  const otpRefs = useRef([]);
  const [verifyText, setVerifyText] = useState(""); // Input text (confirm/decline)
  const [remarks, setRemarks] = useState(""); // Remarks when "decline" is typed
  const [refundStatus, setRefundStatus] = useState(""); // To track refund status (confirm/decline)
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  // Handle OTP input change
  const handleOTPChange = (event, index) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input
      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  // Handle key down (for backspace)
  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  // Start timer after OTP request
  useEffect(() => {
    let timerInterval;
    if (OTPModalOpen && !resendOTP) {
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerInterval);
            setResendOTP(true);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [OTPModalOpen, resendOTP]);

  // Request OTP
  const requestOTP = async () => {
    try {
      setLoading(true);
      const body = {
        transactionID: item?.TransactionID,
      };

      const token = localStorage.getItem("token");
      const response = await axios.post(
        process.env.REACT_APP_API_LINK + "/rfapi/refund/initiateOTP",
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setResendOTP(false);
        setTimer(60); // Reset the timer
        setConfirmationStep(false); // Skip confirmation after initiating OTP
      } else {
        commonToasts.errorToast("Please try again later.");
        setLoading(false);
      }
    } catch (error) {
      //console.error("Error sending OTP request:", error);
      commonToasts.errorToast(
        "Error sending OTP request. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  // verify OTP
  const verifyOTP = async (enteredOTP) => {
    try {
      setLoading(true);
      const body = {
        transactionID: item?.TransactionID,
        otp: enteredOTP,
        refundRemarks: remarks,
      };

      const token = localStorage.getItem("token");
      const response = await axios.post(
        process.env.REACT_APP_API_LINK + "/rfapi/refund/verifyOTP",
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setOTPModalOpen(false);
        commonToasts.errorToast("otp has been verified successfully");
      } else {
        commonToasts.errorToast("Please try again later.");
        setLoading(false);
      }
    } catch (error) {
      //console.error("Error sending OTP request:", error);
      setRemarks("");
      commonToasts.errorToast(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP submission
  const handleOTPSubmit = () => {
    const enteredOTP = otp.join("");
    if (enteredOTP.length === 6) {
      if (!remarks) {
        commonToasts.errorToast("Please provide remarks.");
        return;
      }

      verifyOTP(enteredOTP);

      // Handle OTP submission logic
    } else {
      commonToasts.errorToast("Please enter a 6-digit OTP.");
    }
  };

  const handleProceed = async () => {
    if (verifyText === "confirm") {
      // Proceed with the refund
      requestOTP(item);
    } else if (verifyText === "decline") {
      // Handle decline
      if (!remarks) {
        commonToasts.errorToast("Please provide remarks for declining.");
        return;
      }

      // Make API call for refund decline with remarks
      try {
        const body = {
          transactionID: item?.TransactionID,
          refundRemarks: remarks,
        };

        const token = localStorage.getItem("token");
        const response = await axios.post(
          process.env.REACT_APP_API_LINK + "/rfapi/refund/declineRequest",
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          setOTPModalOpen(false);
          commonToasts.errorToast("Refund has been declined.");
          // Perform further steps for decline (e.g., close modal)
        } else {
          commonToasts.errorToast("Error: Unable to decline refund.");
        }
      } catch (error) {
        //console.error("Error declining refund:", error);
        commonToasts.errorToast("Error: Something went wrong.");
      }
    } else {
      commonToasts.errorToast(
        "Please type 'confirm' to proceed or 'decline' to reject."
      );
    }
  };

  return !(item?.RefundStatus === "initiated") ? (
    <div className="modalx">
      <div
        className="modalx-content2"
        style={{
          maxHeight: "100%",
          overflowY: "auto",
          padding: "16px",
        }}
      >
        <div
          style={{
            alignSelf: "flex-end",
            cursor: "pointer",
            padding: "8px 16px",
            borderRadius: "4px",
            backgroundColor: "#edf2f7",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={() => setOTPModalOpen(false)}
        >
          Close
        </div>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "14px",
            color: "#6b7280", // Grey text color
            textAlign: "center",
            textTransform: "none", // Prevent first letter capitalization
          }}
        >
          Refund for this transaction has been completed or declined by the
          checker
        </div>
      </div>
    </div>
  ) : (
    <div>
      {OTPModalOpen && confirmationStep ? (
        // Step 1: Ask the user if they want to proceed with the refund
        <div className="modalx">
          <div
            className="modalx-content2"
            style={{
              maxHeight: "100%",
              overflowY: "auto",
              padding: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "flex-end",
                cursor: "pointer",
                padding: "8px 16px",
                borderRadius: "4px",
                backgroundColor: "#edf2f7",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => setOTPModalOpen(false)}
            >
              Close
            </div>

            <div>
              <h3
                style={{
                  marginBottom: "16px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Refund Initiation
              </h3>
            </div>

            <div
              style={{
                marginBottom: "16px",
                fontSize: "14px",
                color: "#6b7280", // Grey text color
                textAlign: "center",
                textTransform: "none", // Prevent first letter capitalization
              }}
            >
              Refund for this transaction has been initiated. Do you wish to
              proceed with the remaining steps for completing the refund process
              or reject the refund request ?
            </div>

            <div
              style={{
                marginBottom: "16px",
                fontSize: "14px",
                color: "#6b7280", // Grey text color
                textAlign: "center",
                textTransform: "none", // Prevent first letter capitalization
              }}
            >
              NOTE : Type "confirm" to proceed with further steps or "decline"
              to decline the refund request
            </div>

            {/* Verification Input */}
            <div style={{ marginTop: "5px" }}>
              <div className="customx-input-group" style={{ maxWidth: "100%" }}>
                <input
                  type="text"
                  className="customx-form-control border-light"
                  style={{ minWidth: 400 }}
                  value={verifyText}
                  onChange={(event) => setVerifyText(event.target.value)}
                  placeholder="Type 'confirm' or 'decline' to proceed"
                />
                <br />
              </div>
              {/* Remarks field */}

              {verifyText === "decline" && (
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  <textarea
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    placeholder="Please provide remarks for decline"
                    rows="3"
                    style={{
                      width: "80%",
                      padding: "8px",
                      borderRadius: "4px",
                      //   border: "1px solid #ccc",
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  alignSelf: "center",
                  cursor: loading ? "not-allowed" : "pointer", // Change cursor to not-allowed when loading
                  padding: "8px 16px",
                  borderRadius: "4px",
                  backgroundColor:
                    verifyText === "confirm" || verifyText === "decline"
                      ? "#e21923"
                      : "#edf2f7",
                  color:
                    verifyText === "confirm" || verifyText === "decline"
                      ? "white"
                      : "#ff9500",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textAlign: "center", // Ensures the text is centered in the button
                  pointerEvents: loading ? "none" : "auto", // Disable click when loading
                  opacity: loading ? 0.6 : 1, // Dim the button when loading
                }}
                onClick={handleProceed}
              >
                {verifyText === "decline"
                  ? "Proceed to decline"
                  : loading
                  ? "please wait..."
                  : "Proceed to refund"}
              </div>
            </div>
          </div>
        </div>
      ) : // Step 2: Show OTP modal and countdown timer after confirmation
      loading ? (
        <div className="modalx">
          <div
            className="modalx-content2"
            style={{
              maxHeight: "100%",
              overflowY: "auto",
              padding: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "flex-end",
                cursor: "pointer",
                padding: "8px 16px",
                borderRadius: "4px",
                backgroundColor: "#edf2f7",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => setOTPModalOpen(false)}
            >
              Close
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                height: "50vh",
                backgroundColor: "white",
              }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                draggable="false"
                className="lovin-it"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="modalx">
          <div
            className="modalx-content2"
            style={{
              maxHeight: "100%",
              overflowY: "auto",
              padding: "16px",
            }}
          >
            <div
              style={{
                alignSelf: "flex-end",
                cursor: "pointer",
                padding: "8px 16px",
                borderRadius: "4px",
                backgroundColor: "#edf2f7",
                fontSize: "14px",
                fontWeight: "bold",
              }}
              onClick={() => setOTPModalOpen(false)}
            >
              Close
            </div>

            <div>
              <h3
                style={{
                  marginBottom: "16px",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Confirm OTP
              </h3>
            </div>

            <div
              style={{
                marginBottom: "16px",
                fontSize: "14px",
                color: "#6b7280", // Grey text color
                textAlign: "center",
              }}
            >
              OTP has been sent to your registered email.
            </div>

            {/* OTP Input */}
            <div
              style={{
                display: "flex",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "18px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                  onChange={(event) => handleOTPChange(event, index)}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  ref={(ref) => (otpRefs.current[index] = ref)}
                />
              ))}
            </div>

            {/* remarks field */}
            <div
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              <textarea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Please provide remarks"
                rows="1"
                style={{
                  width: "50%",
                  padding: "8px",
                  borderRadius: "4px",
                  //   border: "1px solid #ccc",
                }}
              />
            </div>

            {/* Timer */}
            <div style={{ textAlign: "right" }}>
              {resendOTP ? (
                <div
                  onMouseEnter={() => setIsHovered(true)} // When mouse enters
                  onMouseLeave={() => setIsHovered(false)} // When mouse leaves
                  onClick={() => requestOTP()} // Resend OTP
                  style={{
                    padding: "10px 20px",
                    width: "80%",
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "#000000",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    textDecoration: isHovered ? "underline" : "none",
                  }}
                >
                  Resend OTP
                </div>
              ) : (
                <div
                  style={{
                    width: "75%",
                    textAlign: "right",
                    fontSize: "12px",
                  }}
                >{`Resend OTP in ${timer}s`}</div>
              )}
            </div>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={handleOTPSubmit}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Submit OTP
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
