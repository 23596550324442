import React, { useEffect, useState } from "react";
import "./Register.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { setToken, setRefreshToken } from "./utils/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import transbnklogo from "./assets/TransBnk Logo TM (4).png";
import Spinner from "react-bootstrap/Spinner";
import { encryptData, decryptData } from "./utils/encryptionUtils";
import commonToasts from "./components/CommonToast";
const Register = () => {
  const [email, setEmail] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [fullName, setFullName] = useState("");
  const [otp, setOTP] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [location, setLocation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRegisterButton, setshowRegisterButton] = useState(false);
  const [showOTPDialog, setshowOTPDialog] = useState(false);
  const navigate = useNavigate();
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!email || !password || !confirmPassword || !agreeTerms) {
      commonToasts.errorToast(
        "Please fill in all required fields and agree to the terms."
      );
      return;
    }

    if (password !== confirmPassword) {
      commonToasts.errorToast("Passwords do not match.");
      return;
    }

    setLoading(true); // Set loading to true

    const encryptedData = encryptData({
      email,
      password,
      fullName,
      mobileNumber,
      companyName,
      website,
      location,
    });

    try {
      const response = await axios.post(
        // " https://apihub.trusthub.in:9100" + '/api/registerInitialize',
        " https://apihub.trusthub.in/rfapi" + "/registerInitialize",
        // process.env.REACT_APP_API_LINK + '/api/registerInitialize',
        { encryptedData }
      );

      if (response.status === 200) {
        const data = response.data;
        // data;
        if (data.message === "OTP sent successfully") {
          setshowOTPDialog(true);
        }
        // setToken(data.token);
        // setRefreshToken(data.refreshToken);
        // navigate("/introduction");
      } else {
        //console.error('Register failed');
        commonToasts.errorToast("Register failed"); // handle the Register error
      }
    } catch (error) {
      //console.error('Error during Register:', error);
      // error.response.data;
      commonToasts.errorToast("Register failed:" + error.response.data.error);
    } finally {
      setLoading(false); // Set loading back to false regardless of success or failure
    }
  };

  const handleFormSubmit2 = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!email || !password || !confirmPassword || !agreeTerms) {
      commonToasts.errorToast(
        "Please fill in all required fields and agree to the terms."
      );
      return;
    }

    if (password !== confirmPassword) {
      commonToasts.errorToast("Passwords do not match.");
      return;
    }

    setLoading(true); // Set loading to true

    const encryptedData = encryptData({
      email,
      password,
      fullName,
      mobileNumber,
      companyName,
      website,
      location,
      otp,
    });

    try {
      const response = await axios.post(
        " https://apihub.trusthub.in/rfapi" + "/registerInitializeVerify",
        // process.env.REACT_APP_API_LINK + '/api/registerInitializeVerify',
        { encryptedData }
      );

      if (response.status === 200) {
        const data = response.data;
        // data;
        if (data.message === "User registered successfully") {
          navigate("/login");
        }
        // setToken(data.token);
        // setRefreshToken(data.refreshToken);
        // navigate("/introduction");
      } else {
        //console.error('Register failed');
        commonToasts.errorToast("Register failed"); // handle the Register error
      }
    } catch (error) {
      // error.response.data.error;
      //console.error('Error during Register:', error);
      commonToasts.errorToast("Register failed:" + error.response.data.error); // handle the Register error
    } finally {
      setLoading(false); // Set loading back to false regardless of success or failure
    }
  };
  const maskEmail = (email) => {
    const atIndex = email.indexOf("@");
    if (atIndex > 0) {
      const maskedPart = email.slice(1, atIndex - 1).replace(/./g, "*");
      return email[0] + maskedPart + email.slice(atIndex - 1);
    }
    return email;
  };
  return (
    <div className="Register-container">
      <div className="left-side">
        <div className="button-container">
          <button className="buttonRegisterimage">Privacy Policy</button>
          <button className="buttonRegisterimage">Terms of Use</button>
          <button className="buttonRegisterimage">LinkedIn</button>
        </div>
      </div>
      <div className="right-side">
        <form className="Register-form" onSubmit={handleFormSubmit}>
          <div className="register_api-hub-info">
            <img
              className="moblogo"
              style={{
                width: "50%",
              }}
              src={transbnklogo}
              alt=""
            />
            <h2 className="register_form-title">Register to the API Hub</h2>
            {/* <p className="register_form-description">Enter your email and password to sign in!</p> */}
          </div>

          <div className="form_row">
            {/* Full Name */}
            <label className="form_label2">
              <p style={{ color: "black", margin: 0, padding: 0 }}>
                Full Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className="form-input2"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </label>

            {/* Mobile Number */}
            <label className="form_label2">
              <p style={{ color: "black", margin: 0, padding: 0 }}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="number"
                className="form-input2"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                required
              />
            </label>
          </div>

          {/* Company Name */}
          <label className="form_label2">
            Company Name
            <input
              type="text"
              className="form-input2"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </label>

          {/* Email */}
          <label className="form_label2">
            <p style={{ color: "black", margin: 0, padding: 0 }}>
              Email <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="email"
              className="form-input2"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>

          {/* Website */}
          <label className="form_label2">
            Website
            <input
              type="url"
              className="form-input2"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </label>

          {/* Location */}
          <label className="form_label2">
            Location
            <input
              type="text"
              className="form-input2"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </label>

          <div className="form_row">
            {/* Password */}
            <label htmlFor="password" className="form-label2">
              <p style={{ color: "black", margin: 0, padding: 0 }}>
                Password<span style={{ color: "red" }}> *</span>
              </p>
              <div className="password-input-container">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  className="form-input2"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </div>
            </label>

            {/* Re-Enter Password */}
            <label htmlFor="confirmPassword" className="form-label2">
              <p style={{ color: "black", margin: 0, padding: 0 }}>
                Re-Enter Password<span style={{ color: "red" }}> *</span>
              </p>
              <div className="password-input-container">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Enter your password"
                  className="form-input2"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
                <span
                  className="password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </div>
            </label>
          </div>

          {/* Terms and Conditions */}
          <div className="form_row2">
            <input
              type="checkbox"
              className="register_checkbox"
              checked={agreeTerms}
              onChange={(e) => setAgreeTerms(e.target.checked)}
              required
            />
            I agree with Privacy Policy, Terms & Conditions.
            <span style={{ color: "red" }}> *</span>
          </div>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>{" "}
            </div>
          ) : showOTPDialog ? (
            <>
              <label htmlFor="Enter OTP " className="form-label2">
                {"Enter OTP recieved on " + maskEmail(email)}
              </label>
              <input
                type="text"
                className="form-input2"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOTP(e.target.value)}
                required
              />
              <br />
              <p
                type="submit"
                className="resend"
                style={{ marginTop: "10px" }}
                onClick={handleFormSubmit}
              >
                Resend OTP
              </p>
              <button
                type="submit"
                className="register_form-button"
                style={{ marginTop: "10px" }}
                onClick={handleFormSubmit2}
              >
                Submit
              </button>
            </>
          ) : (
            <button type="submit" className="register_form-button">
              Register
            </button>
          )}

          <div className="register_not-registered">
            Already have an account?{" "}
            <a href="/login" className="register_form-link">
              Sign In
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
