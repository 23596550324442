import "./Main.scss";
import McDLogo from "./assets/McDLogo.png";
import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation hook
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FaStore } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { TbFileReport } from "react-icons/tb";
import { FaFileAlt } from "react-icons/fa";
import { MdApproval } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { GiHelp } from "react-icons/gi";
import { RiLogoutBoxLine } from "react-icons/ri";
import TransactionsIcon from "../assets/icons/transactionHistory.svg";
// import StoresIcon from "./assets/icons/stores.svg";
// import ReportsIcon from "./assets/icons/stores.svg";
// import SettingsIcon from "./assets/icons/settings.svg";
// import HelpIcon from "./assets/icons/info.svg";
import {
  getToken,
  removeToken,
  getRefreshToken,
  removeRefreshToken,
  setRefreshToken,
  isAuthenticated,
} from "../utils/auth";
import febi from "../assets/FebiText.svg";

const SidebarContent = () => {
  const location = useLocation(); // Get current location
  const navigate = useNavigate();

  // Function to determine if a given path matches the current location
  const isActive = (path) => {
    return location.pathname.toLowerCase() === path.toLowerCase();
  };
  const handleLogout = () => {
    removeToken();
    removeRefreshToken();
  };
  return (
    <div
      className={`sidebar2`}
      style={{
        backgroundColor: "white",
      }}
    >
      <Nav className="flex-column ">
        <Navbar.Brand
          href="/Dashboard"
          style={{
            shapeOutside: "20",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={McDLogo}
            alt="McDLogo"
            style={{
              width: "80%",
              height: "auto",
              padding: 30,
              alignSelf: "center",
            }}
          />
        </Navbar.Brand>
        <br />
        {/* Menu */}
        <Nav.Item className="navx-menu-heading">
          Menu {localStorage.getItem("role")}
        </Nav.Item>
        {/* Add the Stores submenu */}
        <LinkContainer
          to="/Stores"
          style={{
            display: localStorage.getItem("role") === "maker" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${isActive("/Stores") ? "-active" : ""}`}
          >
            {/* <img
              src={''}
              alt="StoreIcon"
              style={{
                marginRight: 10,
              }}
            /> */}
            <FaStore
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Stores
          </Nav.Link>
        </LinkContainer>
        {/* Add the Managers submenu */}
        <LinkContainer
          to="/managers"
          style={{
            display: localStorage.getItem("role") === "maker" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${
              isActive("/managers") ? "-active" : ""
            }`}
          >
            <GrUserManager
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Managers
          </Nav.Link>
        </LinkContainer>
        {/* Add the Transactions submenu */}
        <LinkContainer
          to="/Transactions"
          style={{
            display: localStorage.getItem("role") === "maker" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${
              isActive("/Transactions") ? "-active" : ""
            }`}
          >
            <img
              src={TransactionsIcon}
              alt="StoreIcon"
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Transactions
          </Nav.Link>
        </LinkContainer>
        {/* Add the Reports submenu */}
        <LinkContainer
          to="/Reports"
          style={{
            display: localStorage.getItem("role") !== "admin" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${isActive("/Reports") ? "-active" : ""}`}
          >
            {/* <img
              src={''}
              alt="ReportsIcon"
              style={{
                marginRight: 10,
              }}
            /> */}
            <TbFileReport
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Reports
          </Nav.Link>
        </LinkContainer>
        {/* Add the Reports submenu */}
        <LinkContainer
          to="/FileStatus"
          style={{
            display: localStorage.getItem("role") === "maker" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${
              isActive("/FileStatus") ? "-active" : ""
            }`}
          >
            {/* <img
              src={''}
              alt="ReportsIcon"
              style={{
                marginRight: 10,
              }}
            /> */}
            <FaFileAlt
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            File Upload
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/ApprovalTray"
          style={{
            display:
              localStorage.getItem("role") === "checker" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${
              isActive("/ApprovalTray") ? "-active" : ""
            }`}
          >
            {/* <img
              src={''}
              alt="ReportsIcon"
              style={{
                marginRight: 10,
              }}
            /> */}
            <MdApproval
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Approval Tray
          </Nav.Link>
        </LinkContainer>
        <LinkContainer
          to="/AdminControl"
          style={{
            display: localStorage.getItem("role") === "admin" ? "flex" : "none",
          }}
        >
          <Nav.Link
            className={`navx-menu-item${
              isActive("/AdminControl") ? "-active" : ""
            }`}
          >
            <TbFileReport
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Admin
          </Nav.Link>
        </LinkContainer>
        {/* Others */}
        <Nav.Item className="navx-menu-heading" style={{ marginTop: "10%" }}>
          Others
        </Nav.Item>
        {/* Add the Settings submenu */}
        <div>
          {/* <Nav.Link
            className={`navx-menu-item${
              isActive("/Settings") ? "-active" : ""
            }`}
          >
            <IoMdSettings
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Settings
          </Nav.Link> */}
        </div>
        {/* Add the Help submenu */}
        <div>
          <Nav.Link
            className={`navx-menu-item${isActive("/Help") ? "-active" : ""}`}
          >
            <GiHelp
              style={{
                fontSize: "25px",
                color: "#00000",
                marginRight: "10px",
              }}
            />
            Help
          </Nav.Link>
        </div>
        <LinkContainer to="/" onClick={handleLogout}>
          {/* <Nav.Link className={`navx-menu-item${isActive("/Help") ? "-active" : ""}`}>
                        <img
                            src={HelpIcon}
                            alt="HelpIcon"
                            style={{
                                marginRight: 10,
                            }}
                        />
                        Logout
                    </Nav.Link> */}
          {!isAuthenticated() ? (
            <Nav.Link
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign In
            </Nav.Link>
          ) : (
            <Nav.Link
              onClick={() => {
                ("logout");
                removeToken();
                removeRefreshToken();
                navigate("/login");
              }}
            >
              <RiLogoutBoxLine
                style={{
                  fontSize: "25px",
                  color: "#00000",
                  marginRight: "10px",
                }}
              />
              Logout
            </Nav.Link>
          )}
        </LinkContainer>
      </Nav>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          marginBottom: "5px",
          marginTop: "40%",
        }}
      >
        <div>Powered by</div>
        <img height={50} width={80} src={febi} alt="" />
      </div>
    </div>
  );
};

export default SidebarContent;
