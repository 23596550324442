import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import styled from "styled-components";
import LoaderButton from "./commonButton";
import { LogoutFromHome } from "./logout";

const Main = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 10001;
`;

const Box = styled.div`
  position: relative;
  width: 500px;
  background: #ffffff;
  border-radius: 6px;
  opacity: 1;
  padding: 30px;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
`;

const SessionExpiredPopup = ({}) => {
  const logout = () => LogoutFromHome();

  return (
    <Main>
      <Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <RiErrorWarningLine
            style={{ color: "red", width: "65px", height: "65px" }}
          />
        </div>
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "10px",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "#003366", // Dark blue
              fontSize: "22pt",
              paddingBottom: "10px",
            }}
          >
            Your session has been expired !
          </span>
          <span
            style={{
              fontSize: "18pt",
              textAlign: "center",
              wordWrap: "break-word",
              color: "#707070", // Dark grey
              paddingBottom: "10px",
            }}
          >
            Please click the button to login again. Don't worry, we kept all of
            your filters and breakdowns in place.
          </span>
          <div
            style={{
              margin: "auto",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <LoaderButton
              type={"button"}
              disableState={""}
              action={logout}
              isLoading={""}
              buttonText={"Logout"}
            />
          </div>
        </div>
      </Box>
    </Main>
  );
};

export default SessionExpiredPopup;
