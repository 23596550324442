const CryptoJS = require("crypto-js");
function convertToHash2(plaintext, key) {
  // let ciphertext = CryptoJS.AES.encrypt(plaintext, key).toString();
  let ciphertext = CryptoJS.AES.encrypt(plaintext, CryptoJS.SHA256(key), {
    mode: CryptoJS.mode.ECB,
  }).toString();
  //
  return ciphertext;
}
function convertToText2(encrypted, key) {
  try {
    var decrypted = CryptoJS.AES.decrypt(
      encrypted.toString(),
      CryptoJS.SHA256(key),
      { mode: CryptoJS.mode.ECB }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {}
}
function GES(body, secret) {
  var encryptedBody = {};
  for (var prop in body) {
    if (body.hasOwnProperty(prop)) {
      var value = body[prop];
      if (Array.isArray(value)) {
        var encryptedArray = value.map((item) =>
          convertToHash2(String(item), secret)
        );
        encryptedBody[prop] = encryptedArray;
      } else if (typeof value === "object" && value !== null) {
        var encryptedObject = GES(value, secret); // Recursively decrypt nested objects
        encryptedBody[prop] = encryptedObject;
      } else {
        var encryptedValue = convertToHash2(String(value), secret);
        encryptedBody[prop] = encryptedValue;
      }
    }
  }
  return encryptedBody;
}
function GDS(body, secret) {
  var decryptedBody = {};
  for (var prop in body) {
    if (body.hasOwnProperty(prop)) {
      var value = body[prop];
      if (Array.isArray(value)) {
        var decryptedArray = value.map((item) => convertToText2(item, secret));
        decryptedBody[prop] = decryptedArray;
      } else if (typeof value === "object" && value !== null) {
        var decryptedObject = GDS(value, secret); // Recursively decrypt nested objects
        decryptedBody[prop] = decryptedObject;
      } else {
        var decryptedValue = convertToText2(value, secret);
        decryptedBody[prop] = decryptedValue;
      }
    }
  }
  return decryptedBody;
}

function GESRecords(records, secretKey) {
  if (!Array.isArray(records)) {
    // Encrypt single object directly
    return GES(records, secretKey);
  }

  // Encrypt array of records
  return records.map((record) => GES(record, secretKey));
}
function GDSRecords(records, secretKey) {
  if (!Array.isArray(records)) {
    // Encrypt single object directly
    return GDS(records, secretKey);
  }

  // Encrypt array of records
  return records.map((record) => GDS(record, secretKey));
}

function convertToHash(message) {
  let secret = "28Oct02Nov15Aug";
  let ciphertext = CryptoJS.AES.encrypt(message, secret).toString();
  //
  return ciphertext;
}

export { GES, GDS, GDSRecords, GESRecords, convertToHash };
