import React, { useState } from 'react'
import {
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SidebarContent from './Sidebar';


const Managers = () => {

 const [isModalOpen, setIsModalOpen] = useState(false)

 const toggleModal = () => {
  setIsModalOpen(!isModalOpen)
 }


    return (
        <React.Fragment>
           <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#f5f5f5",
      }}>
            <SidebarContent />
            <div
        style={{
          padding: "0%",
          margin: "0%",
          flex: 1,
        }}
      >
         <NavV />
         <div
          style={{
            padding: "1.52%",
            backgroundColor: "#f5f5f5",
          }}
        >
              <div
            style={{
              borderRadius: "20px",
              padding: "1.52%",
              backgroundColor: "white",
            }}
          >
             <div
              style={{
                display: "flex",
                margin: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
                  <div
                className="d-flex flex-wrap"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <div
                  className="custButton float-right"
                  onClick={() => {
                    // setUploadTransactions(false);
                    toggleModal();
                  }}
                >
                  <p className="custButton-text">Create Manager</p>
                  <FontAwesomeIcon icon={faPlus} className="custButton-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
           </div>
          {
            isModalOpen === true && (
              <div className="modalx">
              <div className="modalx-content">
                {/* selectedFile input */}
                <label className="editlabel">Add Manager</label>

                <div className="fields-container">
                  {/* {columns.map((field, fieldindex) => (
                    <div key={fieldindex} className="field-wrapper">
                      <h2 className="modalxh2">{field}</h2>
                      <input
                        type="text"
                        value={''
                          // EditRow[field]
                        }
                        onChange={(e) =>
                          // handleInputChange(
                          //   e,
                          //   field,
                          //   fieldindex,
                          //   EditRow["STORE_ID"]
                          // )
                          {}
                        }
                        className="input-fieldx"
                      />
                    </div>
                  ))} */}
                </div>
                <br />

                <button
                  className="custButton2"
                  // onClick={handleEditSubmit}
                >
                  Submit
                </button>

                <button className="custButton2" onClick={() => toggleModal()} style={{cursor:'pointer'}}>
                  Close
                </button>
              </div>
              </div>
            )
          }
        </React.Fragment>
    )
}

export default Managers



const NavV = () => {
    return (
      <p
        className="align-self-end"
        style={{
          padding: "2%",
          display: "flex",
          backgroundColor: "white",
          margin: 0,
          marginTop: "0rem",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "0.1px solid gray",
          // , marginBottom: 20
        }}
      >
        {"Home > "}
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            marginLeft: "0.51rem",
            fontWeight: "700",
          }}
        >
          {"Managers"}
        </p>
      </p>
    );
  };

