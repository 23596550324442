import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { components } from "react-select";
import { Button, Pagination, Table } from "react-bootstrap";
import search_icon from "../assets/search_icon.svg";
import download_icon from "../assets/download_icon.svg";
import refresh_icon from "../assets/refresh_icon.svg";
import Calendar from "react-calendar";
import { default as ReactSelect } from "react-select";
import "./Main.scss";
import McDLogo from "./assets/McDLogo.png";
import axios from "axios";
import * as XLSX from "xlsx";
import { GDSRecords } from "../ENDE";
import Swal from "sweetalert2";

import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  faChartLine,
  faPlus,
  faDownload,
  faSort,
  faEdit,
  faRefresh,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import SidebarContent from "./Sidebar";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import commonToasts from "./CommonToast";
// const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

export default function AdminControl() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [selectedSearchFilter, setSelectedSearchFilter] = useState(null);
  const [isDateConstVisible, setIsDateConstVisible] = useState(false);
  const [isSortFieldDBVisible, setisSortFieldDBVisible] = useState(false);
  const [SortFieldDB, setSortFieldDB] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [DateConst, setDateConst] = useState(new Date());
  const [totalCount, setTotalCount] = useState(0);
  const [selectedFile, setselectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const columns = ["email", "fullName", "created_at", "role", "Action"];
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [IsUploading, setIsUploading] = useState(false);
  const keys = [
    // "id",
    "email",
    "password",
    "fullName",
    "created_at",
    // "updated_at",
    "role",
  ];
  const handleFileChange = (e) => {
    try {
      // e.target.files[0];
      setselectedFile(e.target.files[0]);
    } catch (error) {
      // error;
    }
  };

  const encryptFile = async (file) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const fileData = new Uint8Array(reader.result);
        const encryptedData = CryptoJS.AES.encrypt(
          fileData.toString(),
          "your-secret-key"
        );
        const blob = new Blob([encryptedData.toString()], {
          type: "application/octet-stream",
        });
        resolve(blob);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };
  function TableStatus({ text }) {
    const textx = text?.toLowerCase();
    // (textx);
    // (textx === 'failed');
    const color =
      textx === "failed"
        ? "#EB5757"
        : textx === "cancelled"
        ? "#F2994A"
        : "#12A474";
    const bgcolor =
      textx === "failed"
        ? "#FFDBDB"
        : textx === "cancelled"
        ? "#FFF3DB"
        : "#BBF3E0";
    return (
      <span
        style={{
          backgroundColor: bgcolor,
          color: color,
          fontSize: 12,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 8,
          textTransform: "capitalize",
        }}
      >
        {text}
      </span>
    );
  }
  const toggleModal = (close) => {
    setModalOpen(!modalOpen);
    setProgress(0); // Reset progress when modal opens
    if (close === true) {
      setselectedFile(null);
      setModalOpen(false);
    }
  };
  const [sortOrder, setSortOrder] = useState(null);
  const [data, setdata] = useState(null);
  const [editMode, seteditMode] = useState(false);
  const [editedValues, setEditedValues] = useState(
    Array(data?.length).fill({})
  );
  const [EditRow, setEditRow] = useState({
    email: "",
    password: "",
    fullName: "",
    role: "Maker",
  });

  const [errors, setErrors] = useState({});
  const roles = ["Maker", "Checker"]; // Define available roles
  const validateField = (field, value) => {
    let error = "";
    switch (field) {
      case "email":
        if (!value) {
          error = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid.";
        }
        break;
      case "password":
        const errors = {}; // Initialize an object to store password validation errors

        if (!value) {
          error = "Password is required.";
        } else if (value.length < 8) {
          errors.length = "Password must be at least 8 characters long.";
        }
        if (!/[A-Z]/.test(value)) {
          errors.uppercase =
            "Password must include at least one uppercase letter.";
        }
        if (!/[a-z]/.test(value)) {
          errors.lowercase =
            "Password must include at least one lowercase letter.";
        }
        if (!/[0-9]/.test(value)) {
          errors.number = "Password must include at least one number.";
        }
        if (!/[^A-Za-z0-9]/.test(value)) {
          errors.specialChar =
            "Password must include at least one special character.";
        }

        // If there are any errors, join them into a single error message
        if (Object.keys(errors).length > 0) {
          error = Object.values(errors).join(" ");
        }
        break;
      case "fullName":
        if (!value) {
          error = "Full name is required.";
        }
        break;
      case "role":
        if (!value) {
          error = "Role is required.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const toggleEditMode = () => {
    setErrors({});
    seteditMode(!editMode);
  };

  // "edit mode is", editMode;

  const handleAddSubmit = async (index) => {
    try {
      // "edit row is", EditRow;
      const token = localStorage.getItem("token");
      // Encrypt the edited data
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(EditRow),
        "secret_key"
      ).toString();
      JSON.stringify(EditRow);

      // Send the encrypted data to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINK}/rfapi/createAdminRow`,
        {
          data: encryptedData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // response.data;

      if (response.data.message === "User created successfully") {
        Swal.fire({
          title: "Success",
          text: "User created successfully",
          icon: "success",
        });
        toggleEditMode(index);
        fetchData();
        toggleModal(true);
      } else if (
        EditRow["id"] &&
        response.data.message === "Password updated successfully"
      ) {
        Swal.fire({
          title: "Success",
          text: "Password updated successfully",
          icon: "success",
        });
        toggleEditMode(index);
        fetchData();
        toggleModal(true);
      } else {
        // Handle unexpected response messages
        throw new Error(
          response.data.message || "Unexpected response from server"
        );
      }
    } catch (error) {
      //console.error("Error submitting edited data:", error);

      // Determine the type of error and set the appropriate message
      let errorMessage;

      // Check if the error is from the server response
      if (error.response) {
        // Server responded with a status code outside the range of 2xx
        errorMessage =
          error.response.data.error ||
          error.response.data.message || // Fallback to a general message
          "Failed to update user"; // Default message
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage =
          "No response received from server. Please try again later.";
      } else {
        // Something happened in setting up the request
        errorMessage = error.message || "An error occurred. Please try again.";
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    }
  };
  const handleEditSubmit = async (index) => {
    try {
      // "edit row is", EditRow;
      const token = localStorage.getItem("token");
      // Encrypt the edited data
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(EditRow),
        "secret_key"
      ).toString();
      JSON.stringify(EditRow);

      // Send the encrypted data to the backend
      const response = await axios.put(
        `${process.env.REACT_APP_API_LINK}/rfapi/updateAddedUser`,
        {
          data: encryptedData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // response.data;

      if (response.data.message === "User updated successfully") {
        Swal.fire({
          title: "Success",
          text: "User updated successfully",
          icon: "success",
        });
        toggleEditMode(index);
        fetchData();
        toggleModal(true);
      } else if (
        EditRow["id"] &&
        response.data.message === "Password updated successfully"
      ) {
        Swal.fire({
          title: "Success",
          text: "Password updated successfully",
          icon: "success",
        });
        toggleEditMode(index);
        fetchData();
        toggleModal(true);
      } else {
        // Handle unexpected response messages
        throw new Error(
          response.data.message || "Unexpected response from server"
        );
      }
    } catch (error) {
      //console.error("Error submitting edited data:", error);

      // Determine the type of error and set the appropriate message
      let errorMessage;

      // Check if the error is from the server response
      if (error.response) {
        // Server responded with a status code outside the range of 2xx
        errorMessage =
          error.response.data.error ||
          error.response.data.message || // Fallback to a general message
          "Failed to update user"; // Default message
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage =
          "No response received from server. Please try again later.";
      } else {
        // Something happened in setting up the request
        errorMessage = error.message || "An error occurred. Please try again.";
      }

      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditRow({
      ...EditRow,
      [field]: value,
    });

    const error = validateField(field, value);
    setErrors({
      ...errors,
      [field]: error,
    });
  };

  const isFormValid = () => {
    const newErrors = {};
    Object.keys(EditRow).forEach((field) => {
      const error = validateField(field, EditRow[field]);
      if (error) {
        newErrors[field] = error;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    // "in", EditRow, isFormValid();

    if (isFormValid()) {
      // errors;
      handleAddSubmit(EditRow);
    }
  };

  const ccList = [
    // { value: "id", label: "ID" },
    { value: "email", label: "Email" },
    // { value: "password", label: "Password" },
    { value: "role", label: "Role" },
    { value: "fullName", label: "Full Name" },
    { value: "created_at", label: "Created At" },
    // { value: "updated_at", label: "Updated At" },
  ];

  const handlePageChange = (value) => {
    setPage(value);
  };
  const pageCount = Math.ceil(totalCount / pageSize);
  const onDateConstChange = (value) => {
    setDateConst(value);
    setIsDateConstVisible(!isDateConstVisible);
  };
  function getDateLabel(date) {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const inputDate = new Date(date);
    inputDate.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and milliseconds

    if (inputDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (inputDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return inputDate.toISOString().substring(0, 10);
    }
  }
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      var email = localStorage.getItem("email");
      setIsLoading(true);
      // ({
      //   sortField: SortFieldDB,
      //   searchValue: searchValue,
      //   searchFilter: selectedSearchFilter,
      //   date: DateConst,
      // });
      await axios({
        url: process.env.REACT_APP_API_LINK + `/rfapi/getAdminData`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "json",
        // withCredentials: true,

        data: {
          sortField: SortFieldDB,
          searchValue: searchValue,
          searchFilter: selectedSearchFilter,
          page: page,
          sortOrder: !sortOrder ? "asc" : sortOrder,
          date: DateConst,
          email: email,
        },
      }).then((response) => {
        if (response) {
          var datax = response.data.data;
          // datax;
          // datax = datax.AdminControl;
          // datax;
          // typeof datax;
          setdata(datax);
          setTotalCount(response.data.totalCount);
        }
      });
    } catch (err) {
      // ("error fetching ");
      commonToasts.errorToast("error fetching data");
      // err;
    } finally {
      setIsLoading(false);
    }
  };

  // "row data", data;

  // const downloaddata = async () => {
  //   try {
  //     const response = await axios({
  //       url: process.env.REACT_APP_API_LINK + `/rfapi/getStoreData`,
  //       method: "POST",
  //       responseType: "json",
  //       // withCredentials: true,

  //       data: {
  //         sortField: SortFieldDB,
  //         searchValue: searchValue,
  //         searchFilter: selectedSearchFilter,
  //         page: page,
  //         download: true,
  //       },
  //       responseType: "blob", // Set the response type to blob to handle binary data
  //     });

  //     // Create a blob object from the response data
  //     const blob = new Blob([response.data], { type: "text/csv" });

  //     // Create a download link
  //     const downloadLink = document.createElement("a");
  //     downloadLink.href = window.URL.createObjectURL(blob);
  //     downloadLink.download = "data.csv";

  //     // Append the link to the body and click it to trigger the download
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();

  //     // Cleanup
  //     document.body.removeChild(downloadLink);
  //   } catch (error) {
  //     //console.error("Error downloading data:", error);
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);
  // useEffect(() => {
  //         fetchData(SortFieldDB, searchValue, selectedSearchFilter,page,sortOrder);
  // }, [page]);
  useEffect(() => {
    if (
      SortFieldDB ||
      (searchValue && selectedSearchFilter) ||
      (!searchValue && !selectedSearchFilter)
    ) {
      fetchData();
    }
  }, [
    SortFieldDB,
    searchValue,
    selectedSearchFilter,
    sortOrder,
    page,
    DateConst,
  ]);

  const handleSort = (field) => {
    // field;
    // SortFieldDB;
    // sortOrder;
    if (field === SortFieldDB) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortFieldDB(field);
      setSortOrder("asc");
    }
  };
  const pageNumbers = [];
  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }

  const getPageItems = () => {
    const items = [];

    // Add Prev button
    items.push(
      <Pagination.Prev
        key="prev"
        onClick={() => {
          if (page > 1) {
            handlePageChange(page - 1);
          }
        }}
        disabled={page <= 1}
      />
    );

    // Add page numbers
    for (
      let i = Math.max(1, page - 4);
      i <= Math.min(pageCount, page + 5);
      i++
    ) {
      items.push(
        <Pagination.Item
          key={i}
          active={page === i}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add Next button
    items.push(
      <Pagination.Next
        key="next"
        onClick={() => {
          if (page < pageCount) {
            handlePageChange(page + 1);
          }
        }}
        disabled={page >= pageCount}
      />
    );

    return items;
  };

  const NavV = () => {
    return (
      <p
        className="align-self-end"
        style={{
          padding: "2%",
          display: "flex",
          backgroundColor: "white",
          margin: 0,
          marginTop: "0rem",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "0.1px solid gray",
          // , marginBottom: 20
        }}
      >
        <p
          className="align-self-end"
          style={{
            margin: 0,
            marginTop: "0rem",
            marginLeft: "0.51rem",
            fontWeight: "700",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/AdminControl");
          }}
        >
          {"Admin Control"}
        </p>
      </p>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#f5f5f5",
      }}
    >
      <SidebarContent />
      <div
        style={{
          padding: "0%",
          margin: "0%",
          flex: 1,
        }}
      >
        <NavV />
        <div
          style={{
            padding: "1.52%",
            backgroundColor: "#f5f5f5",
          }}
        >
          <div
            style={{
              borderRadius: "20px",
              padding: "1.52%",
              backgroundColor: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                margin: 0,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h1
                                style={{
                                    display: "inline-block",
                                    width: "27%",
                                    fontSize: "1rem",
                                    fontWeight: "700",
                                }}
                            >
                                AdminControl
                            </h1> */}
              <div
                className="d-flex flex-wrap"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "stretch",
                }}
              >
                <div className="customx-input-group" style={{ maxWidth: 800 }}>
                  <img
                    src={search_icon}
                    alt=""
                    width={40}
                    style={{
                      padding: 10,
                    }}
                    id="basic-addon1"
                  />
                  <input
                    type="text"
                    className="customx-form-control"
                    style={{
                      minWidth: 400,
                    }}
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    placeholder="Search here"
                  />
                  <ReactSelect
                    className="customx-form-control border-light"
                    styles={{
                      control: (baseStyle, state) => ({
                        ...baseStyle,
                        borderColor: "white",
                        "&:hover": {
                          borderColor: "white",
                        },
                        boxShadow: 0,
                      }),
                    }}
                    placeholder={"Search Field"}
                    options={ccList}
                    hideSelectedOptions={false}
                    isClearable={true}
                    onChange={setSelectedSearchFilter}
                    value={selectedSearchFilter}
                  />
                </div>

                {/* <div
                  style={{ position: "relative", margin: 0, display: "flex" }}
                >
                  <button
                    className="custButton"
                    onClick={() => {
                      setIsDateConstVisible(!isDateConstVisible);
                    }}
                  >
                    <p className="custButton-text">{getDateLabel(DateConst)}</p>
                  </button>
                  {isDateConstVisible && (
                    <div className="calendar-container">
                      <Calendar
                        onChange={onDateConstChange}
                        className="m-2"
                        value={DateConst}
                      />
                    </div>
                  )}
                </div> */}

                <button
                  className="custButton"
                  // className="custButton"
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    border: "none",
                  }}
                  onClick={() => {
                    setisSortFieldDBVisible(!isSortFieldDBVisible);
                  }}
                >
                  <p className="custButton-text">
                    {SortFieldDB ? SortFieldDB : "Select Sort"}
                  </p>
                  <FontAwesomeIcon icon={faSort} className="custButton-icon" />
                  {isSortFieldDBVisible && (
                    <div className="calendar-container">
                      <div className="FilterDropDown-menu">
                        {keys.map((key) => (
                          <span
                            key={key}
                            className="FilterDropDown-item-text"
                            onClick={() => {
                              setSortFieldDB(key);
                            }}
                          >
                            {key}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </button>
                {/* <div className="custButton"
                                   onClick={() => downloaddata()}>
                                    <p className="custButton-text">Download</p>
                                    <FontAwesomeIcon icon={faDownload} className="custButton-icon" />
                                </div> */}
                <div className="custButton" onClick={() => fetchData()}>
                  <p className="custButton-text">Refresh</p>
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className="custButton-icon"
                  />
                </div>

                <div
                  className="custButton"
                  onClick={() => {
                    setEditRow([]);
                    toggleEditMode();
                  }}
                >
                  <p className="custButton-text">Create User</p>
                  <FontAwesomeIcon icon={faPlus} className="custButton-icon" />
                </div>
              </div>
            </div>
            <>
              {data != null && !isLoading && data.length > 0 ? (
                <div>
                  <Table
                    className="mt-3"
                    hover
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                    responsive
                  >
                    <thead>
                      <tr
                        style={{
                          color: "#809FB8",
                          borderBottomWidth: 1,
                          borderBottomColor: "#b4a7d6",
                          fontWeight: "600",
                          textTransform: "uppercase",
                          height: 50,
                          verticalAlign: "middle",
                        }}
                      >
                        {columns.map((column) => (
                          <td
                            key={column}
                            onClick={() => {
                              handleSort(column);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {column}
                          </td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          {columns.map((field, fieldindex) => {
                            if (field === "Action") {
                              // Action column (Edit and Delete icons)
                              return (
                                <td
                                  key={fieldindex}
                                  style={{
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className="custButton-icon"
                                    onClick={() => {
                                      toggleEditMode(index);
                                      // "item is", item;

                                      setEditRow(item);
                                    }}
                                    style={{ marginRight: "10px" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="custButton-icon"
                                    onClick={() => {
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You won't be able to revert this!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Confirm",
                                        cancelButtonText: "Cancel",
                                      }).then(async (result) => {
                                        if (result.isConfirmed) {
                                          try {
                                            // Encrypt the data to delete
                                            const encryptedData =
                                              CryptoJS.AES.encrypt(
                                                JSON.stringify(item),
                                                "secret_key"
                                              ).toString();
                                            const token =
                                              localStorage.getItem("token");
                                            const response = await axios.post(
                                              `${process.env.REACT_APP_API_LINK}/rfapi/DeleteAdminRow`,
                                              { data: encryptedData },
                                              {
                                                headers: {
                                                  Authorization: `Bearer ${token}`,
                                                },
                                              }
                                            );

                                            if (
                                              response.data.message ===
                                              "User Deleted successfully"
                                            ) {
                                              Swal.fire({
                                                title: "Success",
                                                text: "User deleted successfully",
                                                icon: "success",
                                              });
                                              fetchData();
                                            }
                                          } catch (error) {
                                            //console.error(
                                            //   "Error deleting user:",
                                            //   error
                                            // );
                                            Swal.fire({
                                              title: "Error",
                                              text:
                                                error.message ||
                                                "Failed to delete user",
                                              icon: "error",
                                            });
                                          }
                                        }
                                      });
                                    }}
                                  />
                                </td>
                              );
                            } else {
                              // Regular columns (email, fullName, created_at, role)
                              return (
                                <td
                                  key={fieldindex}
                                  style={{
                                    color: "#06152B",
                                    fontWeight: "400",
                                    fontSize: "0.81rem",
                                  }}
                                >
                                  <p>{item[field]}</p>
                                </td>
                              );
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>

                    {/* <h1>asdkjnaskds</h1> */}
                  </Table>
                  {/* Math.ceil(totalCount / pageSize) */}
                  <Pagination style={{ float: "right", marginTop: "50px" }}>
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    {getPageItems()}
                    <Pagination.Last
                      onClick={() => handlePageChange(pageCount)}
                    />
                  </Pagination>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                    height: "70vh",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/3/36/McDonald%27s_Golden_Arches.svg"
                    draggable="false"
                    className="lovin-it"
                  />
                  {/* <Spinner animation="border" /> */}
                </div>
              )}
            </>

            {editMode && (
              <div className="modalx">
                <div className="modalx-content">
                  <label className="editlabel">
                    {EditRow["id"] ? "Edit User Details" : "Create User"}
                  </label>

                  <div className="fields-container">
                    {keys
                      .filter(
                        (x) =>
                          x !== "id" &&
                          !["created_at", "updated_at"].includes(x)
                      )
                      .map((field, index) =>
                        field !== "role" ? (
                          <div key={index} className="field-wrapper">
                            <h2 className="modalxh2">{field}</h2>
                            <input
                              type="text"
                              value={EditRow[field]}
                              placeholder={` Enter ${field}`}
                              disabled={
                                EditRow["id"] && field !== "password"
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleInputChange(e, field)}
                              className="input-fieldx"
                            />
                            {errors[field] && (
                              <span className="error-message">
                                {errors[field]}
                              </span>
                            )}
                          </div>
                        ) : (
                          <></>
                        )
                      )}

                    {/* Role dropdown */}
                    <div className="field-wrapper">
                      <h2 className="modalxh2">Role</h2>
                      <select
                        value={EditRow.role}
                        onChange={(e) => handleInputChange(e, "role")}
                        className="input-fieldx"
                        // disabled={editMode ? true : false}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                      {errors.role && (
                        <span className="error-message">{errors.role}</span>
                      )}
                    </div>
                  </div>
                  <br />

                  <button
                    className="custButton2"
                    title={
                      !EditRow.email ||
                      !EditRow.password ||
                      !EditRow.fullName ||
                      !EditRow.role
                        ? "Please fill in all fields before submitting."
                        : ""
                    }
                    disabled={
                      !EditRow.email ||
                      !EditRow.password ||
                      !EditRow.fullName ||
                      !EditRow.role
                    }
                    onClick={EditRow["id"] ? handleEditSubmit : handleSubmit}
                  >
                    Submit
                  </button>

                  <button className="custButton2" onClick={toggleEditMode}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
