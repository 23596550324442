import React from "react";

const LoaderButton = ({
  type = "button",
  disableState,
  action = () => {},
  isLoading,
  buttonText,
  additionalClasses = "",
}) => {
  const buttonStyle = {
    color: "white",
    fontSize: "16px",
    whiteSpace: "nowrap",
    backgroundColor: disableState ? "#4a90e2" : "#0066cc", // Change background color based on disableState
    cursor: disableState ? "not-allowed" : "pointer",
    border: "none",
    padding: "8px 16px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: disableState ? "0.6" : "1",
  };

  const textStyle = {
    fontSize: "16px",
  };

  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const loaderCircleStyle = {
    border: "3px solid #fff",
    borderTop: "3px solid transparent",
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    animation: "spin 1s linear infinite",
    marginRight: "8px",
  };

  // Optional: Add additional class styles if needed
  const combinedClasses = additionalClasses ? `${additionalClasses}` : "";

  return (
    <button
      type={type}
      disabled={disableState}
      onClick={action}
      style={buttonStyle}
      className={combinedClasses}
    >
      {isLoading ? (
        <div style={loaderStyle}>
          <div style={loaderCircleStyle}></div>
          <span style={textStyle}>{buttonText}</span>
        </div>
      ) : (
        <span style={textStyle}>{buttonText}</span>
      )}
    </button>
  );
};

export default LoaderButton;
