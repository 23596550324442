import React, { useState } from "react";
import "./Login.scss";
import transbnklogo from "./components/assets/mcdthumb.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { setToken, getToken, removeToken, setRefreshToken } from "./utils/auth";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encryptData } from "./utils/encryptionUtils";
import commonToasts from "./components/CommonToast";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});

  const validatePassword = (value) => {
    const errors = {};

    if (value.length < 8) {
      errors.length = "Password must be at least 8 characters long";
    }
    if (!/[A-Z]/.test(value)) {
      errors.uppercase = "Password must include at least one uppercase letter";
    }
    if (!/[a-z]/.test(value)) {
      errors.lowercase = "Password must include at least one lowercase letter";
    }
    if (!/[0-9]/.test(value)) {
      errors.number = "Password must include at least one number";
    }
    if (!/[^A-Za-z0-9]/.test(value)) {
      errors.specialChar =
        "Password must include at least one special character";
    }

    return errors;
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const validationErrors = validatePassword(e.target.value);
    setErrors(validationErrors);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    localStorage.clear();
    const encryptedData = encryptData({
      email,
      password,
    });
    if (email === "shardul@gmail.com" && password === "kageyama") {
      localStorage.setItem("email", email);
      navigate("/stores");
      commonToasts.successToast("admin login");
      localStorage.setItem("role", "admin");
      // localStorage.setItem("role", "checker");
      // localStorage.setItem("role", "maker");
      setToken("xyzsa");
      window.location.reload();
    } else {
      if (Object.keys(errors).length === 0) {
        try {
          // (" https://apihub.trusthub.in/rfapi" + "/login");
          const response = await axios.post(
            process.env.REACT_APP_API_LINK + "/rfapi/login",
            // process.env.REACT_APP_API_LINK + '/api/registerInitialize',
            { encryptedData }
          );

          if (response.status === 200) {
            const data = response.data;
            // Store the JWT token securely, e.g., in localStorage
            localStorage.setItem("token", data.token);
            localStorage.setItem("email", email);
            localStorage.setItem("rtoken", data.refreshToken);
            localStorage.setItem("role", data.role.toLocaleLowerCase());

            setToken(data.token);
            setRefreshToken(data.refreshToken);
            if (data.role.toLocaleLowerCase() === "checker") {
              navigate("/Reports");
            } else if (data.role.toLocaleLowerCase() === "admin") {
              navigate("/AdminControl");
            } else {
              navigate("/stores");
            }
            window.location.reload();
          }
        } catch (error) {
          //console.error("Response data:", error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            //console.error("Response data:", error.response.data);
            //console.error("Response status:", error.response.status);
            //console.error("Response headers:", error.response.headers);

            if (error.response.status === 401) {
              // Invalid password
              commonToasts.errorToast(
                "Invalid password. Please check your password and try again."
              );
            } else if (
              error.response.status === 400 &&
              error.response.data.error === "User not found"
            ) {
              // User not found
              commonToasts.errorToast(
                "User not found. Please register before attempting to log in."
              );
            } else {
              // Generic error
              commonToasts.errorToast(
                "Login failed. Please check your credentials and try again."
              );
            }
          } else if (error.request) {
            // The request was made but no response was received
            //console.error("Request data:", error.request);
            commonToasts.errorToast(
              "No response from the server. Please try again later."
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.error("Error message:", error.message);
            commonToasts.errorToast(
              "An unexpected error occurred. Please try again."
            );
          }
        }
      } else {
        // ("password error");
        // errors;
        // errors.length;
      }
    }
  };
  return (
    <div className="login-container">
      <div className="left-side">
        <div className="button-container">
          <button className="buttonloginimage">Privacy Policy</button>
          <button className="buttonloginimage">Terms of Use</button>
          {/* <button className="buttonloginimage">LinkedIn</button> */}
        </div>
      </div>
      <div className="right-side">
        <form className="login-form" onSubmit={handleFormSubmit}>
          <div className="api-hub-info">
            <h2 className="form-title">Sign in to Refund Portal</h2>
            <p className="form-description">
              Enter your email and password to sign in!
            </p>
          </div>

          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email address"
            className="form-input"
            value={email}
            onChange={handleEmailChange}
          />

          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="password-input-container">
            <input
              type={passwordVisible ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Enter your password"
              className="form-input"
              value={password}
              onChange={handlePasswordChange}
            />
            <span
              className="password-toggle"
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
          </div>
          <div style={{ color: "red" }}>
            {Object.keys(errors).map((errorKey) => (
              <p key={errorKey} style={{ margin: 0, fontSize: 13 }}>
                {errors[errorKey]}
              </p>
            ))}
          </div>
          <div className="password-forgot"></div>

          <button type="submit" className="form-button">
            Sign In
          </button>

          <div className="not-registered"></div>
        </form>
      </div>
    </div>
  );
};

export default Login;
